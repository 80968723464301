.titleDescription {
    font-size: 18px;
}

.line {
    border-bottom: solid 1px var(--color-primary);
    width: 100%;
    margin: 10px 0;
}

.arrow {
    transition: all 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}

@media (max-width: 960px) {
    .trigger {
        margin-top: 20px !important;
    }

    .titleDescription {
        font-size: 16px;
    }
}
