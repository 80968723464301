.container {
    width: 100%;
    height: 60vh;
    display: flex;
}

@media (max-width: 960px) {
    .container {
        height: 80vh;
    }
}
