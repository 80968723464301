.container {
    padding: 30px 0;
}

.light {
    color: #2b3f57;
}

.dark {
    color: #fff;
    background: linear-gradient(180deg, #1e1a33, #4d4964);
}
