.input {
    color: var(--color-primary) !important;
    min-height: 28px !important;
    border-bottom: 1px solid #004378 !important;
    width: 100%;
    font-family: "Poppins", Gadget, sans-serif !important;
    transition: all 0.3s ease;
}

.input::placeholder {
    color: var(--color-placeholder) !important;
    font-family: "Poppins", Gadget, sans-serif !important;
}

.input:focus {
    height: 150px;
}
