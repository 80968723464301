.container {
    width: 90%;
}

.lineForm {
    width: 103%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 20px;
}

.button {
    padding-right: 40px !important;
    padding-left: 40px !important;
}

@media (max-width: 980px) {
    .lineForm {
        display: flex;
        flex-direction: column !important;
        width: unset;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 20px;
    }
    .button {
        height: 29px !important;
        font-size: 13px !important;
    }

    .contentBtn {
        margin-top: 15px !important;
        margin-bottom: 20px;
    }
}
