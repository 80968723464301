.container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 15%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: var(--zIndex-mobileMenu);
    background-color: var(--color-tertiary-bg);
    overflow: auto;
    transform: translateX(-100vw);
    transition: all 0.4s ease-in-out;
}

.fullWidthContainer {
    right: 0;
}

.opened {
    display: block;
    transform: translateX(0);
}

.closeIcon {
    padding-right: 18px;
}

.overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 801;
    background-color: rgba(0, 0, 0, 0.4);
}

.header {
    padding: 10px 0;
    border-bottom: 1px solid var(--color-border);
}

.logo {
    padding-left: 18px;
}

.title {
    text-align: center;
    margin: 0 0 0 24px;
}

.children {
    padding: 10px 18px 18px 18px;

    display: flex;
    flex-direction: column;
    min-height: calc(100svh - 62px);
}
