.container {
    margin-bottom: 110px;
}

.content {
    height: 100%;
    width: 100%;
}

.contentImage {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    float: right;
    height: 100%;
}

.articles {
    right: 50%;
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
}

.image {
    height: 80vh;
}

.titleContent {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.title {
    font-family: Poppins, serif;
    font-size: clamp(20px, 1.6vw, 32px);
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.1em;
    margin-bottom: 0;
}

@media (max-width: 960px) {
    .container {
        margin-bottom: 50px;
    }
    .titleContent {
        margin-bottom: 20px !important;
    }
    .image {
        position: static;
        height: 100%;
        width: 90%;
        margin-right: 15px;
        margin-left: 15px;
    }
    .articles {
        position: static;
        transform: translate(0, 0);
    }
    .content {
        display: flex;
        flex-direction: column;
        position: static;
    }
}

@media (max-width: 1250px) {
    .articles {
        left: 5%;
    }
}

.animationLeft {
    -webkit-animation: slide-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.animationTop > div:nth-child(1) {
    -webkit-animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.animationTop > div:nth-child(2) {
    -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.animationTop > div:nth-child(3) {
    -webkit-animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%);
        transition-duration: 1.5s;
        animation-timing-function: ease-in-out;
        opacity: 0.8;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        transition-duration: 1.5s;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
}
@keyframes slide-left {
    0% {
        -webkit-transform: translateX(10%);
        transform: translateX(10%);
        transition-duration: 1.5s;
        animation-timing-function: ease-in-out;
        opacity: 0.8;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        transition-duration: 1.5s;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }
}
