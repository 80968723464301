.link {
    background: #ffffff;
    box-shadow: 3.5px 4px 5px rgba(35, 59, 70, 0.25);
    color: black !important;
    font-size: 24px;
    position: relative;
    width: 99%;
    height: 100px;
    border-radius: 5px;
}

.link:hover {
    -webkit-box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.26);
    cursor: pointer;
}

.img {
    height: 100%;
    width: 100px;
    object-fit: cover;
    border-radius: 5px 0 0 5px;
}

.titleUnit {
    font-size: 21px;
    font-weight: 600;
}

.tilde {
    color: var(--color-primary) !important;
}

.surface,
.price {
    font-size: 16px;
}

.contentCard {
    width: 100%;
    height: 100%;
}

.notDisplayedPrice {
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 600px) {
    .link {
        height: 250px;
    }

    .img {
        width: 100%;
        height: 120px;
    }

    .titleUnit {
        font-size: 16px;
    }

    .surface,
    .price,
    .notDisplayedPrice {
        font-size: 14px;
    }

    .rowSurface {
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .link {
        flex-direction: column;
    }

    .surface,
    .icon {
        margin-right: 10px !important;
    }

    .tilde {
        margin: 0 10px !important;
    }
}
