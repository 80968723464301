.img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.place {
    background-color: rgba(24, 40, 54, 0.2);
    color: white;
    font-size: clamp(14px, 2vw, 16px);
    bottom: 10%;
    right: 0;
    border: 2px solid var(--color-secondary);
    border-right: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    line-height: 18px;
    padding: 10px 60px 10px 20px;
    font-weight: 600;
}

.cityRegion {
    font-size: clamp(10px, 1vw, 12px);
    font-weight: 400;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(24, 40, 54, 0.5);
    height: 100vh;
}

@media (max-width: 680px) {
    .container,
    .img {
        height: 100vh;
    }

    .place {
        padding: 7px 20px 7px 7px;
    }
}
