.title {
    font-size: 32px !important;
    font-weight: var(--font-weight-600) !important;
}

.details {
    flex: 1;
    padding-right: 25px;
}

.CTA {
    max-width: 425px;
}

.price {
    font-size: 28px;
    line-height: 25px;
    color: var(--color-text-black);
}

.from {
    color: var(--color-text-fade);
    font-size: 18px;
}

@media (max-width: 1280px) {
    .details {
        flex: 1;
    }

    .CTA {
        flex: 1;
    }
}

@media (max-width: 960px) {
    .container {
        flex-direction: column;
        margin-top: 20px !important;
    }

    .CTA {
        max-width: unset;
        margin-left: 0 !important;
        margin-top: 35px;
    }

    .details {
        flex: 1;
        padding-right: 0;
    }

    .price {
        font-size: 22px !important;
    }
}
