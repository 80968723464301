.container {
    display: flex;
    flex-direction: column;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: calc(100svh - 150px);
        overflow-y: auto;

        li {
            padding: 8px 18px;
            border-bottom: 1px solid var(--color-border);
            display: flex;
            flex-direction: column;

            .link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                /*width: 100%;*/
                /*height: 100%;*/
                /*text-decoration: none;*/
                /*color: var(--color-primary);*/
                /*font-size: 16px;*/
                /*font-weight: 400;*/
            }

            a {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}

.buttonsBottom {
    position: absolute;
    width: 100%;
    bottom: 20px;
}

.button {
    width: 100%;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: none;
}
