.title {
    font-weight: 700;
    font-size: clamp(28px, 1.6vw, 38px);
    line-height: clamp(34px, 1.6vw, 38px);
    letter-spacing: 0.1em;
    color: var(--color-primary);
    padding: 2%;
    display: flex;
    text-align: center;
}

.content {
    border: 3px solid var(--color-primary);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5%;
}

.firstText {
    padding-right: 18% !important;
    padding-left: 18% !important;
}

.text {
    font-weight: 600;
    font-size: clamp(14px, 1.5vw, 17px);
    line-height: clamp(21px, 1.5vw, 17px);
    text-align: center;
    color: var(--color-font);
    padding: 1.8%;
}

.lastText {
    max-width: 900px;
}
