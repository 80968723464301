.container {
    margin-top: 60px;
    line-height: 1;
}

.title {
    margin-bottom: 25px !important;
    text-align: left !important;
}

.link {
    font-size: 12px;
    margin: 0;
}

.containerLink {
    padding: 10px 5px !important;
}

.containerAdvancedResearches {
    border: 1px solid var(--color-primary);
    padding: 25px 30px;
    border-radius: 10px;
}

@media screen and (max-width: 1000px) {
    .container {
        margin-top: 40px;
    }
}

@media screen and (max-width: 800px) {
    .container {
        margin-top: 20px;
    }
}
