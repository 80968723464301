.btnMore {
    justify-content: center;
    align-items: center;
    border: solid 2px var(--color-primary);
    width: 140px;
    border-radius: 5px;
    padding: 5px 20px;
    margin: auto;
    color: var(--color-primary) !important;
    font-weight: 400;
    white-space: nowrap;
}

.notBtn {
    border: none;
    color: var(--color-primary) !important;
}

@media (max-width: 600px) {
    .btnMore {
        font-size: 14px;
    }
}
