@import "../../../../../_css/variables/variables.css";

.container.root {
    font-family: "Poppins", Gadget, sans-serif;
    font-size: 16px;
}

.container.containerForDelete {
    color: var(--color-delete);
}

.seeMoreButton {
    border: 2px solid var(--color-primary) !important;
    color: var(--color-primary) !important;
    border-radius: var(--border-radius-see-more) !important;
    height: 36px !important;
}

.container.containerForDelete.contained {
    color: #fff;
    background-color: var(--color-delete);
}

.container.sizeSmall {
    min-height: auto;
}

.outlinedPrimary {
    border: 1px solid var(--color-primary) !important;
}

.containedPrimary {
    background-color: var(--color-primary) !important;
    color: var(--color-text-on-primary) !important;
}

.containedSecondary:hover {
    color: var(--color-primary) !important;
    background-color: var(--color-secondary-darker);
}
