.container {
    margin-inline: 0;
    gap: 20px;
    padding-top: 20px;
}

.card {
    width: 100%;
}

.img {
    width: 150px;
    height: 120px;
}

.content {
    margin-left: 20px;
    margin-bottom: 30px;
    width: 75%;
}

.card {
    .img,
    & h2,
    & p {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: shine 1.5s linear infinite;
        -webkit-animation: shine 1.4s linear infinite;
    }

    & h2 {
        height: 20px;
    }

    & p {
        margin-top: 5px;
        height: 15px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
