.container {
    position: relative;
    background-color: var(--color-grey-payment-secondary);
    box-shadow: inset 0 1.5px 3px rgba(17, 77, 130, 0.25);
    border-radius: 50px;
    font-size: clamp(9px, 1.1vw, 11px);
    line-height: clamp(11px, 1.1vw, 13px);
    width: 100%;
}

.halfSection {
    border: 2px transparent;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    white-space: nowrap;
    padding: 12px;
    width: 100%;
}

.selectedPlaceholder {
    z-index: 1;
    position: absolute;
    background: #ffffff;
    border: 2px solid var(--color-primary);
    border-radius: 50px;
    transition: all 0.4s ease 0s;
    height: 100%;
    width: 50%;
}

.selectedPlaceholderBis {
    transform: translateX(100%);
}

@media (max-width: 980px) {
    .container {
        width: 104%;
        margin-left: -2px;
    }
}
