.btn {
    width: 100%;
    padding: 10px 23px !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    border-radius: 5px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    background: #004378 -webkit-linear-gradient(left, #004378, #1670bf 50%, #004378) 0 0 / 60%;
    animation: sweep 4s ease infinite;
}

@keyframes sweep {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0 0;
    }
}
