.container {
    border-radius: var(--border-radius-gallery-propertyPage);
    background-color: white;
    cursor: pointer;
    height: 480px;
}

.swiperContainer {
    text-align: center;
    background-color: black;
    border-radius: 5px;
    overflow: hidden;
}

.mainPhoto {
    position: relative;
    height: 420px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    vertical-align: bottom;
}

.image {
    border-radius: var(--border-radius-gallery-propertyPage);
}

.bottomBorder {
    margin: 5px 0 !important;
    padding: 0 !important;
    height: 1px;
    width: 100%;
    background-color: var(--color-border);
}

.contentSliders {
    height: 100%;
    width: 781px;
    margin-right: 25px;
    overflow-x: hidden;
}

.contentSliderLand {
    width: 100%;
}

.sliderMain {
    width: 100%;
}

.slideVideo {
    height: 420px !important;
    border-radius: var(--border-radius-gallery-propertyPage);
}

.mainFullHeight {
    height: 480px;
}

.seeAllPhotos {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 2;
}

.actions,
.bottomActions,
.labels {
    z-index: 2;
}

.actions {
    right: 22px;
}

.actions {
    top: 12px;
}

.bottomActions {
    bottom: 15px;
    right: 25px;
}

.button {
    font-size: 12px !important;
    width: 78px;
    height: 36px;
}

.purpose {
    position: relative;
    margin-right: 10px;
}

.hiddenBtns {
    visibility: hidden;
}

.labels {
    top: calc(15px + 8px);
    left: calc(15px + 8px);
}

@media (max-width: 1300px) {
    .contentSliders {
        width: calc(100% - 435px);
    }
}

@media (max-width: 960px) {
    .container {
        height: 100%;
        flex-direction: column;
    }
    .labels {
        font-size: 14px;
        top: 15px;
        left: 15px;
    }

    .video,
    .mainPhoto,
    .contentSliders {
        width: 100%;
    }
    .bottomActions {
        bottom: 20px;
        right: calc(15px + 8px);
    }
}
