.container {
    max-height: calc(100vh - 130px);
}

.title {
    font-weight: 700;
    font-size: clamp(20px, 1.5vw, 32px);
    line-height: 48px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
    align-self: center;
}

.content {
    height: calc(75vh - 130px);
    width: 100%;
}

.header {
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 690px) {
    .container {
        max-height: initial;
        margin-top: 30px;
    }

    .title {
        text-align: center;
    }

    .content {
        flex-direction: column;
        height: initial;
        width: 100%;
    }
}
