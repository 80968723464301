:root {
    --zIndex-dashboard-side-column: 100;
    --zIndex-home-figures: 100;
    --zIndex-home-background: 100;
    --zIndex-dashboard-arrows: 200;
    --zIndex-product-card: 700;
    --zIndex-mobileMenu-cross: 725;
    --zIndex-date-picker-dashboard: 750;
    --zIndex-navBar: 800;
    --zIndex-top-navBar: 800;
    --zIndex-searchBar: 800;
    --zIndex-header: 810;
    --zIndex-overlayCompare: 950;
    --zIndex-modal: 1020;
    --zIndex-mobileMenu: 1030;
    --zIndex-overlay: 1100;
    --zIndex-virtualAssistant--popup: 1200;
    --zIndex-mesengerSwipeUp: 1360;
    --zIndex-mesengerIcon: 1360;
    --zIndex-cookieBanner: 1400;
    --zIndex-cookieBannerModal: 1500;
}
