.container {
    width: 70%;
    height: 100%;
}

.dropButton {
    height: 100%;
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    align-items: center;
}

.label {
    height: 100%;
    width: 100%;
    padding: 20px 20px 20px 50px;
    font-weight: 700;
    font-size: clamp(16px, 1.2vw, 16px);
    line-height: clamp(24px, 1.2vw, 28px);
    letter-spacing: var(--letter-spacing-normal);
    text-transform: uppercase;
    background-color: #ffffff;
}

.caseEmpty {
    width: 100px;
    background-color: #ffffff;
    height: 100%;
    padding: 32px;
}

.btn {
    cursor: pointer;
    height: 100%;
    width: 100px;
    background-color: #f3f4f5;
}

.sectionIsOpen {
    border-bottom: 1px var(--color-primary) solid;
}

.dropDown {
    width: 100%;
    max-height: 555px;
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    color: #000000;
}

.extraLabel {
    align-self: flex-end;
    width: 40px;
}

@media (max-width: 980px) {
    .extraLabel {
        display: none;
    }
}

@media (max-width: 600px) {
    .container {
        width: 100%;
        height: 100%;
    }

    .label {
        text-align: center;
        padding: 20px;
    }
}
