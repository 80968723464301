.products {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.title {
    text-align: center;
    font-weight: 700;
    font-size: clamp(20px, 6vw, 24px);
    line-height: 40px;
    letter-spacing: var(--letter-spacing-normal);
    text-transform: uppercase;
    margin-bottom: 30px;
}

.disclaimer {
    color: var(--color-primary);
    font-size: 12px;
    line-height: 18px;
}

@media (max-width: 1400px) {
    .products {
        display: grid;
        flex-direction: unset;
        grid-template-columns: repeat(2, 50%);
        justify-items: center;
    }
}

@media (max-width: 980px) {
    .disclaimer {
        display: none;
    }
    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .containerArrayIsMobile {
        margin: 5px !important;
    }
}

@media (max-width: 600px) {
    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        margin-right: 30px;
        margin-left: 30px;
    }
}
