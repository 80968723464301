.container {
    height: 50vh;
}

.container,
.title {
    color: black;
}

.title {
    font-size: 42px;
}
.description {
    text-align: center;
    max-width: 750px;
    line-height: 2;
}

@media (max-width: 560px) {
    .description {
        line-height: 1.5;
    }
}
