.list {
    ul {
        list-style: none;
        display: flex;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        color: var(--color-text-black);
    }
}

.title {
    font-weight: var(--font-weight-600) !important;
}
