.title {
    font-size: 26px;
    font-weight: 500;
    text-transform: uppercase;
    max-width: 400px;
}

.message {
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 960px) {
    .container {
        flex-direction: column;
    }

    .title {
        max-width: unset;
    }
}
