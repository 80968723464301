.container {
    display: flex;
    justify-content: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileContent {
    display: none;
}

.title {
    color: var(--color-inactive-bg);
    font-size: 26px;
    font-weight: 400;
}

.activeTitle {
    color: var(--color-primary);
}

.number {
    background-color: var(--color-inactive-bg);
    font-size: 22px;
    font-weight: 400;
    width: 37px;
    text-align: center;
    border-radius: 100%;
    color: var(--color-text-on-primary);
    margin-right: 18px;
}

.activeNumber {
    background-color: var(--color-primary);
}

.bar {
    width: 110px;
    height: 2px;
    background-color: var(--color-inactive-bg);
    margin: 0 20px;
}

.activeBar {
    background-color: var(--color-primary);
}

.megaBarContainer {
    margin-top: 32px;
    position: relative;
}

.megaBarBg {
    background-color: var(--color-inactive-bg);
    width: 100%;
    height: 10px;
    border-radius: 10px;
}

.megaBar {
    position: absolute;
    top: 0;
    background-color: var(--color-primary);
    width: 20%;
    height: 10px;
    border-radius: 10px;
    transition: all 0.2s;
}

.stepOneMegaBar {
    width: 20%;
}

.stepTwoMegaBar {
    width: 60%;
}

.stepThreeMegaBar {
    width: 100%;
}

@media (max-width: 850px) {
    .megaBarContainer {
        margin-top: 12px;
    }

    .content {
        display: none;
    }

    .mobileContent {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
