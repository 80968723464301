.card {
    border-radius: 2px;
    background: white;
    height: 480px;
    margin-bottom: 20px;
}

.img {
    width: 100%;
    height: 240px;
}

.content {
    padding: 10px;
}

.card {
    .img,
    h2,
    p {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: shine 1.5s linear infinite;
        -webkit-animation: shine 1.4s linear infinite;
    }

    h2 {
        margin-top: 10px;
        height: 40px;
    }

    p {
        margin-top: 10px;
        height: 30px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media (max-width: 1400px) {
    .img {
        height: 190px;
    }
    .card {
        height: 380px;
    }
}

@media (max-width: 1000px) {
    .img {
        height: 140px;
    }
    .card {
        height: 280px;
    }
}
