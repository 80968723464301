.titleSectionCheckbox {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
    box-shadow: 0px 2.46575px 2.46575px rgba(17, 77, 130, 0.25);
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.label {
    line-height: 1.3;
}

.contentCheckbox {
    margin-top: 1px;
    height: 240px;
    box-shadow: 0px 4px 4px rgba(17, 77, 130, 0.25);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 15px 30px 15px 30px;
}

.item {
    width: 250px;
    height: 45px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1em;
    color: #000000;
    display: flex;
}

.checkbox {
    height: 21px !important;
    width: 21px !important;
    margin-right: 20px;
    background: #fafbfc;
}

.item input[type="checkbox"]:before {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background: #fafbfc;
    box-shadow: inset 0px 1.664px 3.328px rgba(17, 77, 130, 0.25) !important;
}
.item input[type="checkbox"]:checked:before {
    content: "\2716";
    color: var(--color-primary) !important;
    width: 22px;
    height: 22px;
    background: #fafbfc;
    display: flex;
    align-items: center;
    justify-content: center;
}
