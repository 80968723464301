.content {
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}

.contentTitle {
    text-align: center;
}
.img {
    height: 190px;
    width: 100%;
    object-fit: cover;
    filter: grayscale(40%) brightness(40%) saturate(250%);
    -webkit-filter: grayscale(40%) brightness(40%) saturate(250%);
}

.btn {
    color: white !important;
    font-weight: 500;
    border-radius: var(--border-radius);
    border: 2px solid var(--color-secondary);
    white-space: nowrap;
    cursor: pointer;
    font-size: 18px;
}

.btn:hover {
    background-color: var(--color-secondary);
}

.title {
    color: white;
    font-weight: 600;
    font-size: clamp(20px, 1.2vw, 24px);
}
