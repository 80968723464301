.container {
    position: fixed;
    width: 100%;
    background: rgba(0, 67, 120, 0.85);
    backdrop-filter: blur(8px);
    padding: 64px;
    bottom: 0;
    z-index: var(--zIndex-cookieBanner);
    color: white;
    animation: all ease 0.5s;
}

.logo {
    min-width: 104px;
}

.title {
    font-size: 30px;
    font-weight: 700;
}

.content {
    max-width: 70%;
    gap: 10px;
}

.text {
    font-size: 20px;
    line-height: 1.4;
    & a {
        color: white;
        text-decoration: underline;
        font-weight: 600;
    }

    & a:hover {
        color: var(--color-secondary);
    }
}

@media (max-width: 1280px) {
    .container {
        padding: 32px;
        gap: 50px;
    }

    .title {
        font-size: 20px;
    }

    .text {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .container {
        flex-direction: column;
        gap: 15px;
    }

    .logo {
        min-width: 73px;

        & img {
            width: 73px !important;
        }
    }

    .content {
        max-width: initial;
    }

    .actions {
        margin-top: 10px;
        gap: 20px;
    }
}
