.label {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-right: 10%;
}

.container {
    width: clamp(100%, 5vw, 355px);
    height: 45px;
    background: #ffffff;
    box-shadow: 0px 2.46575px 2.46575px rgba(17, 77, 130, 0.25);
    margin-right: 35px;
    cursor: pointer;
    position: relative;
}

.content {
    height: 100%;
}

.arrowContent {
    width: 45.62px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary {
    box-shadow: none;
    border-bottom: 1px solid var(--color-primary);
    border-radius: 2px;
}

.arrowContentSecondary {
    height: 88%;
    margin-top: 3px;
}

.item {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item:hover {
    background-color: #f3f4f5;
    font-weight: 600;
}

.contentItems {
    position: relative;
    box-shadow: 0px 2.46575px 2.46575px rgba(17, 77, 130, 0.25);
    background-color: white;
    max-height: 350px;
    overflow-x: hidden;
    z-index: 999;
    margin-top: 1px;
}

.contentItems :not(:last-child) {
    border-bottom: 1px solid var(--color-primary);
}

.arrow {
    transform: rotate(0deg);
    transition: 0.4s;
}

.arrowDown {
    transform: rotate(180deg);
    transition: 0.4s;
}

.arrowUp {
    transform: rotate(0deg);
    transition: 0.4s;
}

.labelSecondary {
    font-size: 13px;
    color: var(--color-primary);
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    padding: 0;
    margin-left: 0;
}
