.title {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 500;
}

.list {
    list-style-type: none !important;
}

.list > * :hover {
    color: var(--color-secondary-darker) !important;
}

.blockList {
    font-size: 15px;
    font-weight: 400;
    padding-left: 0 !important;
    & li {
        line-height: 1.3 !important;
    }
}
