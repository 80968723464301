.container {
    width: 100%;
    padding-top: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    color: var(--color-primary);
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-normal);
    font-size: clamp(14px, 1.6vw, 24px);
}

.lineYellow {
    width: 90%;
    border-bottom: var(--color-secondary) solid 3px;
    margin-bottom: 10px;
}

.priceSection {
    color: var(--color-primary);
    position: relative;
    font-weight: 700;
    align-items: baseline;
    font-size: clamp(16px, 1.4vw, 39px);
    margin-bottom: 20px;
}

.dollar {
    color: var(--color-primary);
    position: absolute;
    width: fit-content;
    font-size: clamp(12px, 1.4vw, 23px);
    top: -6px;
    left: -6px;
}
.price {
    color: var(--color-primary);
    margin-right: 10px;
    margin-left: 10px;
    line-height: clamp(25px, 1.4vw, 40px);
    font-size: clamp(16px, 1.8vw, 42px);
}
.smallPrice {
    font-size: 40px;
}
.month {
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: clamp(14px, 1.4vw, 17px);
    letter-spacing: 0.2em;
}
.text {
    font-size: 13px;
    font-weight: normal;
}

@media (max-width: 1200px) {
    .dollar {
        left: 0;
    }
}

@media (max-width: 950px) {
    .title {
        font-size: 13px !important;
        letter-spacing: 2px;
        border-left: var(--color-secondary) solid 3px;
        height: 80%;
    }

    .container {
        height: 150px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
    }

    .lineYellow {
        display: none;
    }
    .priceSection {
        margin: 0 !important;
    }

    .dollar {
        top: -10px;
        left: 0;
        right: 5px;
    }
    .month {
        letter-spacing: 0.2em;
    }
}
