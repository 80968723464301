.container {
    border: 1px solid #e2e4eb;
    aspect-ratio: 1;
    border-radius: 10px;
    padding: 20px;
}

.title {
    margin-bottom: 20px;
    color: black;
    align-self: baseline;
}

.imgContainer {
    display: flex;
    height: calc(100% - 30px);
    width: 100%;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 3px;
}
