.cartMenuContainer {
    position: absolute;
    color: var(--color-text);
    padding-top: 2px;
    right: 0;
    top: var(--height-header);
    z-index: 799;
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 1.5s ease-out;
    transition-delay: 0s, 0.1s;
    transition-property: max-height, opacity;
    min-width: 300px;
}

.carteMenuContainer_animate {
    max-height: 1000px;
    opacity: 1;
    transition: all 0.5s ease-in;
}

@media (max-width: 1280px) {
    .cartMenuContainer {
        top: 90px;
    }
}

@media (max-width: 800px) {
    .cartMenuContainer {
        top: 80px;
    }
}
