.container {
    background-color: var(--color-secondLvl-bg);
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
}
.logo {
    align-self: flex-start !important;
    width: 100%;
}

.separator {
    width: 100%;
    border-top: 2px solid #e0e1e8;
    margin: 40px 0;
}

@media (max-width: 960px) {
    .separator {
        margin: 20px 0;
    }
}
