.header {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.container,
.wrapper {
    border-bottom: 1px solid var(--color-border);
    height: var(--height-header);
    background-color: var(--color-firstLvl-bg);
    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.wrapperDashboardPage {
    background-color: var(--color-primary);
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-header);
}

.language {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
}

.wrapperRentOrBuyPage,
.wrapperIsProjectPage {
    position: absolute;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    right: 0;
}

.isTransparent {
    background-color: transparent;
}

.wrapperScrollingHomePage {
    background-color: rgba(24, 40, 54, 0.5);
}

.placeholder {
    height: var(--height-header);
    overflow-x: hidden;
}

.headerContent {
    display: flex;
}

.headerContentMobile {
    display: none;
}

.containerMobile {
    position: absolute;
    margin-top: 15px;
    background-color: white;
    right: 0;

    & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        & li {
            min-width: 30vw;
            padding: 4px 8px;
            border-bottom: 1px solid var(--color-border);
        }
    }
}

@media (max-width: 1280px) {
    .placeholder {
        height: var(--height-headerTablet);
    }

    .placeholderWithoutBanner {
        height: var(--height-headerTablet);
    }

    .wrapper,
    .container,
    .containerIsHomePage {
        height: var(--height-headerTablet);
    }
}

@media (max-width: 940px) {
    .wrapper {
        right: 0;
    }

    .placeholder {
        height: var(--height-headerMobile);
    }

    .placeholderWithoutBanner {
        height: var(--height-headerTablet);
    }

    .wrapper,
    .container,
    .containerIsHomePage {
        height: var(--height-headerMobile);
        z-index: var(--zIndex-header);
    }

    .headerContent {
        display: none;
    }

    .headerContentMobile {
        display: flex;
        z-index: var(--zIndex-top-navBar);
    }
}
