.button {
    position: relative;
    font-weight: 500 !important;
    background-color: #fff !important;
    padding: 8px 16px !important;
    height: var(--height-filters);
    color: var(--color-primary) !important;
    line-height: 1.2 !important;
    border: 1px var(--color-primary) solid !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 100px !important;
}

@media (max-width: 600px) {
    .button {
        box-shadow: 0 0 9px 0 rgba(26, 58, 69, 0.25) !important;
        border: none !important;
    }
}
