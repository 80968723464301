.title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.button {
    width: 100%;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: none;
}

.reset {
    max-width: 140px;
}

.line {
    border-bottom: 1px var(--color-primary) solid;
}

.line:nth-child(4) {
    border-bottom: none;
    padding: 0;
    margin: 0;
}

.line:last-child {
    border-bottom: 1px red solid;
}
