.container {
    margin-top: 50px;
}

.collapse {
    max-height: calc(100px * 3 + 3 * 15px + 15px);
    overflow: hidden;
}

.title {
    margin: 0 !important;
    font-weight: var(--font-weight-600) !important;
}

.open {
    max-height: 100%;
}

@media (max-width: 600px) {
    .container {
        margin-top: 20px;
    }
    .content {
        width: 100%;
    }
    .collapse {
        max-height: calc((250px * 3) + (3 * 15px) + 15px);
    }

    .title {
        font-size: 22px !important;
    }
}
