.container {
    border-radius: 3px;
    background-color: white;
    color: #1e1a33;
    cursor: pointer;
    transition: 0.4s all;
}

.container:hover {
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
}

.bgImage {
    overflow: hidden;
    height: 250px;
    background-color: black;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.icon {
    position: absolute;
}

.price {
    font-size: 18px;
    font-weight: 600;
    color: #151515;
}

.resume {
    color: #151515;
}
