.tabs {
    background-color: white;
    color: var(--color-text-black);
    width: 100%;
    height: 50px;
    margin-bottom: 40px;
}

.tabSelected {
    border: solid 1px var(--color-text-black);
    border-radius: var(--border-radius);
    background-color: var(--color-secondLvl-bg);
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    white-space: nowrap;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 17px;
    cursor: pointer;
}

.tab:hover {
    background-color: var(--color-secondLvl-bg);
}

@media (max-width: 960px) {
    .tabs {
        overflow-x: scroll;
        margin-bottom: 10px;
    }

    .tabs::-webkit-scrollbar {
        display: none;
    }

    .tab {
        font-size: 13px;
        padding: 0 7px;
    }
}
