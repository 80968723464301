.container {
    display: flex;
    align-items: center;

    & ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;

        gap: 5px;

        & li {
            border-bottom: 1px solid transparent;

            & a {
                color: var(--color-text);
                font-weight: 400;
                font-size: 14px;
            }
        }

        & li.main-menu {
            line-height: 1.4 !important;
        }

        & li:hover {
            border-bottom: 1px solid var(--color-primary);
            font-weight: 600;
        }
    }
}

.btn {
    background-color: var(--color-primary);
    padding: 0 12px;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s;

    & a {
        color: var(--color-text-on-primary) !important;
    }
}

.btn:hover {
    border-bottom: 1px solid transparent;
}

.menu {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: 18px;

    & ul {
        gap: 18px;
    }
}

.li-main-menu {
    line-height: 1.4;
}
