.title {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(18px, 1.6vw, 28px);
    height: var(--height-map-header);
    margin-bottom: 0;
    line-height: 1.1;
}

.textHidden {
    color: var(--color-text-black);
    font-size: clamp(14px, 1.8vw, 17px);
}

.titleSection {
    padding: 15px 0;
}

.openArrow {
    transform: rotate(180deg);
}

@media (max-width: 1280px) {
    .titleSection {
        padding: 5px 0;
    }
}

@media (max-width: 800px) {
    .title {
        height: 40px;
    }
}
