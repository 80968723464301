.cards {
    margin-left: -10px;
    margin-right: -10px;
}

.card {
    width: calc(33% - 20px);
}

@media (max-width: 1280px) {
    .card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 780px) {
    .card {
        width: calc(100% - 20px);
    }
}
