.container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 1000px;
    border: solid 3px white;
    padding: 60px 0;
    z-index: var(--zIndex-home-figures);
}

.showcaseContainer {
    width: 100%;
    height: 100vh;
    background: rgba(24, 40, 54, 0.5);
}

.content {
    position: absolute;
    width: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.titleBlock > * {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    color: white;
    font-weight: 700;
}

.title {
    line-height: clamp(36px, 2.9vw, 72px);
    font-size: clamp(36px, 2.8vw, 48px);
    margin-bottom: 0 !important;
    letter-spacing: 0.1em;
    font-weight: 700;
}

.title > span {
    /*For delete the right text box space caused by letter spacing and keep text perfectly center*/
    display: inline-block;
    direction: rtl;
    text-indent: -0.2em;
}

.subTitle {
    font-size: clamp(10px, 2vw, 32px);
    line-height: clamp(36px, 2.5vw, 48px);
    letter-spacing: 0.1em;
    font-weight: 700;
}

.button {
    color: white;
    font-weight: 500;
    border-radius: var(--border-radius);
    padding: 0 13px;
    border: 2px solid transparent;
    font-size: clamp(14px, 2vw, 24px);
    cursor: pointer;
}

.isFiltered {
    border: 2px solid var(--color-secondary);
}

.searchContainer {
    width: 60%;
    min-height: 41px;
}

.searchBarPlaceholder {
    height: 40px;
    background-color: red;
}

.button:hover {
    border-color: var(--color-secondary) !important;
}

@media (max-width: 980px) {
    .component {
        margin-bottom: 50px !important;
    }
}

@media (max-width: 680px) {
    .title {
        padding: 14px;
        font-size: 24px;
        border: 3px solid white;
        letter-spacing: 5px;
    }
    .title > span {
        font-size: 32px;
    }

    .subTitle {
        letter-spacing: 10px;
    }

    .container {
        border: none;
        width: 90%;
    }

    .buttons {
        margin-bottom: 0;
    }

    .searchContainer {
        width: 80%;
    }
}

@media (max-width: 1400px) {
    .container {
        max-width: 800px;
    }
}
