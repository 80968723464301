.background {
    width: 100%;
    height: 200px;
    z-index: 1;
    background-color: rgba(255, 196, 53, 1);
    object-fit: cover;
    top: 50%;
    transform: translate(0, -50%);
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 130px;
}

.content {
    z-index: var(--zIndex-dashboard-side-column);
    background-color: white;
    width: 80%;
    border-radius: 3px;
    -webkit-box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    -moz-box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    vertical-align: center;
}

.swiper {
    height: 230px;
    width: 100%;
}

.title {
    width: 100%;
    font-family: Poppins, serif;
    font-size: clamp(20px, 1.6vw, 16px);
    font-weight: 700;
    line-height: clamp(36px, 1.2vw, 48px);
    letter-spacing: 0.1em;
    justify-self: flex-start;
    text-align: center;
    z-index: var(--zIndex-product-card);
}

.logo {
    height: 100%;
    margin: auto;
    object-fit: contain;
}

@media (max-width: 980px) {
    .container {
        margin-bottom: 50px;
    }
    .title {
        margin-top: 20px;
    }
    .background {
        height: 180px;
    }
}
