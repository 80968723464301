.content,
.container {
    flex: 1;
    min-height: 600px;
    transition: 0.4s all;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.03);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.03);
}

.container {
    color: var(--color-primary);
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: column;
}

.content {
    display: block;
    background-color: white;
    transform: translate(0, 0);
    transition: all 0.15s ease;
    position: relative;
    z-index: 10;
}

.image {
    height: 200px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.date {
    background-color: white;
    padding: 5px;
}

.price {
    font-size: 18px;
    color: var(--color-primary);
    min-height: 75px;
    max-height: 75px;
    overflow: hidden;
    flex: 1;
}

.text {
    font-size: 18px;
    color: #151515;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    flex: 1;
}

.readMore {
    height: 60px;
    font-size: 18px;
    position: relative;
}

.textGradient {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    height: 30px;
    background-image: linear-gradient(to bottom, transparent, white);
}

.container:hover {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.015);
    cursor: pointer;
}
