.title {
    color: var(--color-primary);
    font-size: 26px;
}

.subTitle {
    color: var(--color-primary);
}

@media (max-width: 960px) {
    .title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .subTitle {
        font-size: 16px;
    }
}
