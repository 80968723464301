.root {
    border: 1px solid var(--color-primary);
    border-radius: 6px;
    color: var(--color-primary) !important;
}

.input::placeholder {
    color: var(--color-primary) !important;
    opacity: 1;
}

.input {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.focused {
    border: none;
}

.rootLabel {
    color: var(--color-primary) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
