.sliderThumbnail {
    height: 50px !important;
    width: 782px;
    margin-right: 25px;
}

.sliderThumbnailWithVideo {
    height: 60px;
}
.sliderThumbnail {
    border-radius: var(--border-radius-gallery-propertyPage);
}

.slideThumbnail img {
    height: 50px;
    border-radius: var(--border-radius-gallery-propertyPage);
}

.thumbImg {
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: center;
    vertical-align: bottom;
}

.notCurrentPhoto {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
}

.contentThumbnailVideo {
    width: 100%;
    height: 100%;
}

.thumbnailVideo {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.iconPlay {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 65px !important;
}

@media (max-width: 960px) {
    .sliderThumbnail {
        width: 100%;
    }
}
