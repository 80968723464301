.container {
    width: 100%;
    overflow-y: auto;
    min-width: 350px;
    max-height: 350px;
    border-bottom: 1px solid var(--color-border);
}

.tag {
    font-size: 13px;
    width: fit-content;
    color: white;
    background-color: var(--color-primary);
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 10px;
}

.check {
    color: var(--color-primary);
    padding: 5px !important;
    padding-left: 0 !important;
}

@media (max-width: 800px) {
    .container {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid var(--color-primary);
        min-width: unset;
        max-height: unset;
    }
}
