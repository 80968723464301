.container {
    margin: 10px;
    min-width: 300px;
    width: 100%;
    background: var(--color-tertiary-bg);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    .content {
        padding: 20px 30px;
    }
}

.container {
    .img,
    h2,
    p {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: shine 1.5s linear infinite;
        -webkit-animation: shine 1.4s linear infinite;
    }

    .img {
        height: 200px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    h2 {
        height: 30px;
        margin-bottom: 10px;
    }

    p {
        height: 70px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
