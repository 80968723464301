.cross {
    position: absolute;
    right: 0;
    z-index: var(--zIndex-mesengerIcon);
}

.crossPlaceHolderSmall {
    padding-top: 40px;
}

.crossPlaceHolderLarge {
    background-color: white;
    padding-top: 85px;
}

.transparentBg {
    background-color: transparent;
}
