.card {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    font-family: Poppins, Arial !important;
}

.swiperContainer {
    text-align: center;
    background-color: black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
}

.card:hover {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.015);
    cursor: pointer;
}

.image {
    width: 100%;
    height: 250px;
    position: relative;
    object-fit: cover;
    object-position: center;
    vertical-align: bottom;
}

@media (max-width: 780px) {
    .image {
        height: 300px;
    }
}

.tag {
    color: white;
    padding: 5px 4px 4px 5px;
    -font-weight: 600;
    border-radius: 5px;
    top: 10px;
    left: 10px;
    position: absolute;
    background-color: var(--color-secondary);
    z-index: 2;
}

.title {
    font-size: 18px;
    font-weight: 600 !important;
}

.price {
    font-size: 24px;
    line-height: 25px;
    color: var(--color-primary-lighter);
}

.priceForDashboard {
    font-size: 18px;
}

.from {
    color: var(--color-text-fade);
    font-size: 18px;
}

.fromForDashboard {
    font-size: 14px;
}

.isEditable {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.8;
    background-color: var(--color-primary);
    z-index: var(--zIndex-modal);
    border-radius: 3px;
}
