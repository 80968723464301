.container {
    margin-right: 60px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-primary);
}

.input {
    border: 1px solid var(--color-primary);
}

.btn {
    margin-top: 20px !important;
    padding: 5px 40px !important;
    border-radius: 5px !important;
}

@media (max-width: 600px) {
    .container {
        margin-right: 0;
    }
}
