.headCell,
.mainCell {
    text-align: center;
    width: 20%;
    max-width: 20%;
    background-color: rgba(24, 40, 54, 0.05);
}

.mainCell {
    height: 100%;
}

.firstColumn {
    font-size: 16px;
    width: 30%;
    min-width: 30%;
    color: var(--color-text-black);
    line-height: clamp(14px, 1.6vw, 24px);
}

.firstRow {
    gap: 10px;
}

.row {
    gap: 10px;
    height: 60px;
}

.oddRow {
    background-color: rgba(0, 57, 107, 0.05);
    background-color: #e7ebee;
}
@media (max-width: 950px) {
    .row {
        height: 90px;
        margin-top: 5px;
    }
    .firstColumn {
        font-size: 12px !important;
        width: 20%;
    }
}

@media (max-width: 1200px) {
    .hiddenMobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .hiddenDesktop {
        display: none;
    }
}
