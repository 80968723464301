:root {
    --color-primary: #004378;
    --color-primary-darker: #072446;
    --color-primary-lighter: #1670bf;
    --color-secondary: #ffc435;
    --color-secondary-darker: #b4880e;
    --color-secondary-lighter: #e2af23;
    --color-secondary-localLogic: #f0c150;
    --color-tertiary: #007f88;
    --color-tertiary-darker: #055d6b;
    --color-tertiary-lighter: #007f88;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-success: #0ead69;
    --color-inProgress: #ee4266;
    --color-canceled: #6103c2;
    --color-font: #111;
    --color-placeholder: #7a7e8d;

    --color-text: var(--color-primary);
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-discret: lightgrey;
    --color-text-on-primary: #fff;
    --color-text-black: #000000;

    --color-primary-bg: #fff;
    --color-background-forground: #eff2f7;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #fff;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #f5f6fa;
    --color-inactive-bg: #c4c4c4;

    --color-warning: var(--color-secondary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #c0c0c0;

    --color-header-bg: #f2f7fa;
    --color-header-font: #fff;
    --color-top-header-bg: #333;
    --color-top-header-font: white;
    --color-menu-header-bg: white;
    --color-menu-header-font: #333;
    --color-footer-bg: #f9f9fb;
    --color-footer-font: #f1f1f1;
    --nesto-colors-primary: #e31936;
    --nesto-colors-text-inverted: #ffffff;
    --color-promote-btn: var(--color-primary-darker);
    --color-promote-btn-border: var(--color-primary-darker);
    --color-grey-payment: #fafbfc;
    --color-grey-payment-secondary: #e7ecf1;
}
