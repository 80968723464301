.container {
    border: 1px solid var(--color-inactive-bg);
    border-radius: 4px;
    user-select: none;
    padding: 12px 20px;
    background-color: #fff;
    gap: 20px;
    font-weight: 500;
    font-size: 20px;
}

.activeContainer {
    background-color: #f0f2f7;
    border: 1px solid var(--color-primary);
}

.label {
    display: flex;
    line-height: 0.8;
}

@media (max-width: 768px) {
    .container {
        font-size: 17px;
        padding: 12px 15px;
    }

    .label {
        line-height: normal;
    }
}
