.container {
    width: 100%;
    min-width: 350px;
}

.check {
    padding-left: 5px !important;
}

@media (max-width: 1100px) {
    .container,
    .inputContainer {
        padding: 0;
        min-width: unset;
    }
}
