.container {
    background-color: var(--color-primary-bg);
    padding: 22px 13px;
    border: 1px solid #fcca3e;
    border-radius: 6px;
}

.title {
    font-size: 16px;
    font-weight: 700;
}

.loading {
    height: 180px;
    width: 100%;
}

@media (max-width: 960px) {
    .title {
        font-size: 14px;
    }
}
