.button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 10px 14px 10px 14px !important;
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;

    color: #ffffff !important;
    background: var(--color-primary) !important;
    box-shadow: 0 4px 4px rgba(0, 67, 120, 0.25) !important;
}

.arrow {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}
