.container {
    max-width: 1280px;
    margin: 70px auto 110px;
    height: 850px;
}

.selectContainer {
    z-index: 2;
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
}

.containerExtended {
    height: 850px;
    transition: height 0.25s ease-in;
}

.imgMobile {
    width: 70%;
    float: right;
}

@media (max-width: 980px) {
    .container {
        width: 100%;
        height: 100%;
    }

    .contentImg {
    }
    .imgMobile {
        position: relative;
        display: flex;
        top: 70%;
        justify-content: center;
        float: unset;
        margin: auto;
    }

    .selectContainer {
        position: relative;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 130px;
    }
}

@media (max-width: 600px) {
    .container {
        height: 100%;
    }

    .imgMobile {
        width: 100%;
    }
    .selectContainer {
        position: relative;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0;
        margin-bottom: 100px;
    }
}
