.container {
    width: 100%;
}

.galleryImage {
    width: 55%;
}

.image {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
