.placeholder {
    position: sticky;
    top: 0;
    height: var(--height-filtersSection);
    width: 100%;
    z-index: var(--zIndex-navBar);
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-color: white;
}

@media (max-width: 1280px) {
    .container {
        overflow: hidden;
    }

    .placeholder {
        height: var(--height-filtersSectionTablet);
    }
}

@media (max-width: 800px) {
    .placeholder {
        height: var(--height-filtersSectionMobile);
    }

    .header {
        padding: 20px;
    }
}
