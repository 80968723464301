.container {
    margin-top: 100px;
}

@media (max-width: 940px) {
    .container {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 20px;
    }

    .summaryContainer {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
