.content {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 10px;
    user-select: none;
}

.checkbox {
    height: 12px !important;
    width: 12px !important;
    margin-right: 10px;
    background: var(--color-secondLvl-bg);
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.content input[type="checkbox"]:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background: var(--color-secondLvl-bg);
    border: 1px solid #004378;
    border-radius: 3px;
}

.content input[type="checkbox"]:checked:before {
    content: "\2714";
    border: 1px solid #004378;
    width: 12px;
    height: 12px;
    background: var(--color-secondLvl-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}
