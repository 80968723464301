.containerBtn {
    position: relative;
    border: 1px var(--color-primary) solid;
    border-radius: var(--border-radius);
}
.button {
    position: relative;
    font-weight: 600 !important;
    background-color: #fff !important;
    padding: 8px 16px !important;
    height: var(--height-filters);
    color: var(--color-primary) !important;
    line-height: 1.2 !important;
    border: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.containerBtn :not(:last-child) {
    > .button:after {
        content: "";
        height: 60%;
        border: 1px solid var(--color-border);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.reset {
    text-decoration: underline;
    white-space: nowrap;
}

.resetButton {
    box-shadow: 0 0 9px 0 rgba(26, 58, 69, 0.25) !important;
    border-radius: 100px !important;
}

.subMenuContainer {
    z-index: 6;
    top: 60px;
    min-width: 300px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 9px 0 rgba(26, 58, 69, 0.25);
    list-style-type: none !important;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    opacity: 0;
    background-color: var(--color-primary-bg);
    pointer-events: none;
    overflow-y: auto;
    max-height: calc(100vh - var(--height-filtersSection) - var(--height-header));
}

.btnConfirm {
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
}

.subMenuOpened {
    height: unset;
    flex: 1 1;
    animation: subGroupOpening 0.3s ease-in;
    opacity: 1;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    z-index: 10;
    pointer-events: auto;
}

.chevron {
    transition: 0.3s all;
    color: var(--color-primary);
    font-weight: 600;
}

.cross:hover {
    scale: 1.2;
}

.filterSelected {
    color: var(--color-secondary);
}

.chevronOpened {
    transform: rotate(-180deg);
}

.title {
    font-size: 13px !important;
    margin-block-end: 10px !important;
    margin-block-start: 0 !important;
}

.line {
    width: 95%;
    border-bottom: 1px solid var(--color-primary);
}

@keyframes subGroupOpening {
    from {
        opacity: 0.1;
        transform: perspective(20cm) rotateX(-15deg);
    }
    60% {
        transform: perspective(20cm) rotateX(-10deg);
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 680px) {
    .subMenuContainer {
        left: 75px;
        max-height: unset;
    }

    .subMenuParentContainer {
        position: unset;
    }

    .subMenuOpened {
        min-width: 250px !important;
    }
}

@media (max-width: 400px) {
    .subMenuContainer {
        left: 25px;
    }
}
