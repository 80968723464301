.price {
    font-size: 28px;
    line-height: 30px;
    color: var(--color-text-black);
}

.from {
    color: var(--color-text-black);
    font-size: 18px;
    line-height: 28px;
    margin-right: 20px;
}

.text {
    color: var(--color-text-black);
    font-size: 18px;
}

.textBlue {
    color: var(--color-primary) !important;
}

.textWhite {
    color: white !important;
}

.priceSmall {
    font-size: 15px;
    margin: 0 !important;
}

.fromSmall {
    font-size: 15px;
    margin-right: 3px;
}

@media (max-width: 600px) {
    .from {
        font-size: 14px !important;
    }
    .price {
        font-size: 22px !important;
    }
}

@media (max-width: 960px) {
    .priceSmall {
        line-height: 1.4;
        font-size: 12px !important;
    }

    .fromSmall {
        line-height: 1.4;
        font-size: 12px !important;
    }
}
