.container {
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    padding: 10px 20px 30px 20px;
    max-width: 300px;
}

.textSave {
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-low);
    font-weight: 700;
    font-size: clamp(9px, 1.1vw, 10px);
}

.list {
    list-style: none;
    padding: 0;
    font-size: clamp(11px, 2.4vw, 12px);
    display: flex;
    flex-direction: column;
    text-align: start;
    flex-grow: 1;
    color: #000000;
    width: 200px;
}

.list li {
    line-height: 14px;
    margin-bottom: 10px;
}

/* negative margin to ::before to align second line with the first*/
/*https://stackoverflow.com/questions/66889921/how-to-position-libefore-outside*/
.list li::before {
    content: "■";
    font-size: 10px;
    color: #ffc435;
    vertical-align: middle;
    margin-left: -22px;
    margin-right: 15px;
}

.button {
    padding-inline: 24px;
    font-size: 14px !important;
    text-transform: none !important;
    width: 85% !important;
    border-radius: 2px !important;
    height: 30px !important;
}

.contentInputNumber {
    height: 20%;
    font-size: 14px;
}

.contentToggle {
    width: 100%;
}

@media (max-width: 1080px) {
    .container {
        margin-right: 40px;
        margin-left: 40px;
    }
}

@media (max-width: 980px) {
    .container {
        max-height: 140px;
        height: 490px !important;
        overflow: hidden;
        width: 80%;
        position: relative;
        margin-right: 0;
        margin-left: 0;
        transition: max-height 0.5s ease-in;
        box-shadow: 0 3.2px 3.2px rgba(17, 77, 130, 0.25);
        padding: 20px;
    }

    .openInMobile {
        max-height: 800px;
        transition: max-height 0.5s ease-in;
        padding-bottom: 50px;
    }

    .arrow {
        position: absolute;
        bottom: 12px;
        right: 12px;
        cursor: pointer;
    }

    .arrowReverse {
        position: absolute;
        bottom: 12px;
        right: 12px;
        cursor: pointer;
    }
    .contentInputNumber {
        height: 40px;
    }
}

@media (min-width: 980px) {
    .mobileOnly {
        display: none;
    }
}
