.input {
    border-radius: var(--border-radius);
    border: solid var(--color-border) 1px;
    min-height: var(--input-height);
    padding: 5px 5px 5px 10px;
    /*flex: 1;*/
    font-size: 15px;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
}
