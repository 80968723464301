.container {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    font-family: Poppins, Arial !important;
}

.container:hover {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    /*transform: scale(1.015);*/
    cursor: pointer;
}

.icon {
    position: absolute !important;
    right: 0;
    top: 0;
}
