.link {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-decoration-line: underline;
    color: var(--color-primary);
}

.firstColumn {
    width: 50%;
    height: 100%;
    background-color: var(--color-primary);
    color: white;
    padding-bottom: 80px;
}

.isLoggedIn {
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    color: white;
    padding-bottom: 80px;
}

.contentProducts {
    display: flex;
    width: 45%;
    flex-direction: column;
}

.line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: clamp(13px, 1.2vw, 16px);
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #ffffff;
    margin-top: 40px;
}

.secondColumn {
    width: 30%;
}

.content {
    width: 100%;
}

.title {
    font-weight: 700;
    font-size: clamp(20px, 1.3vw, 32px);
    line-height: 48px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
}

.yellowLine {
    border-bottom: 3px solid #ffc435;
    width: 50%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.contentPrice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textPrice {
    font-weight: 700;
    font-size: clamp(13px, 1.3vw, 16px);
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    margin-bottom: 20px;
}

.totalPrice {
    font-weight: 700;
    font-size: clamp(13px, 1.3vw, 16px);
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
}

.titleSecond {
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 32px);
    line-height: clamp(24px, 1.5vw, 48px);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
    width: 739px;
    text-align: center;
}

.contentSecondColumn {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    border-radius: 2px;
    width: 739px;
    min-height: 45%;
}

.form {
    height: 100%;
}

.text {
    margin-top: 45px;
    width: 40%;
    font-weight: 700;
    font-size: clamp(11px, 1.3vw, 12px);
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2em;
    text-decoration-line: underline;
    color: #ffc435;
}

.button {
    background-color: white !important;
    color: var(--color-primary) !important;
    border-radius: 2px !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    padding-right: 60px !important;
    padding-left: 60px !important;
    margin-top: 10px !important;
}

.buttonDisabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

.btn {
    cursor: pointer;
}

.contentForm {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.loaderContainer {
    position: absolute;
    top: -130px;
    bottom: -130px;
    left: 0;
    right: 0;
    background-color: var(--color-menu-header-font);
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--zIndex-overlay);
}

.checkbox {
    height: 21px !important;
    width: 21px !important;
    margin-right: 20px;
    background: #fafbfc;
}

.termsAndConditions {
    height: 45px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.linkTermsAndConditions {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1em;
    color: var(--color-secondary) !important;
    text-decoration: underline;
}

.termsAndConditions input[type="checkbox"]:before {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background: #fafbfc;
    box-shadow: inset 0px 1.664px 3.328px rgba(17, 77, 130, 0.25) !important;
}
.termsAndConditions input[type="checkbox"]:checked:before {
    content: "\2716";
    color: var(--color-primary) !important;
    width: 22px;
    height: 22px;
    background: #fafbfc;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 980px) {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .firstColumn {
        width: 100%;
    }

    .title {
        text-align: center;
    }
    .secondColumn {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid var(--color-primary);
        border-radius: 2px;
        margin-top: -3px;
    }

    .contentProducts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .line {
        width: 80%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .yellowLine {
        width: 90%;
    }

    .textPrice {
        width: 80%;
    }

    .totalPrice {
        width: 80%;
    }

    .text {
        width: 80%;
    }
    .contentSecondColumn {
        width: 100%;
        border: none;
    }

    .titleSecond {
        margin-top: 25px !important;
        margin-bottom: 0 !important;
    }
}
