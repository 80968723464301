.default {
    color: white;
    background-color: grey;
    font-weight: 400;
    padding: 5px 14px;
    border-radius: var(--border-radius);
    font-size: 15px;
    line-height: 22px;
}

.RENT {
    background-color: var(--color-primary);
}

.BUY {
    background-color: var(--color-secondary-darker);
}
