@-moz-keyframes dotLoader {
    0% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    8.33% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    16.67% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    25% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    33.33% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
    }
    41.67% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    50% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    58.33% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    66.67% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    75% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    83.33% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
    }
    91.67% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    100% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
}
@-webkit-keyframes dots-loader {
    0% {
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    8.33% {
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    16.67% {
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    25% {
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    33.33% {
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
    }
    41.67% {
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    50% {
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    58.33% {
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    66.67% {
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    75% {
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    83.33% {
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
    }
    91.67% {
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    100% {
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
}
@keyframes dotLoader {
    0% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    8.33% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    16.67% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px 14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    25% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    33.33% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) -14px -14px 0 7px;
    }
    41.67% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    50% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    58.33% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) -14px 14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    66.67% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) -14px -14px 0 7px,
            var(--color-secondary) -14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    75% {
        -moz-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
        box-shadow: var(--color-primary) 14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px -14px 0 7px, var(--color-secondary-lighter) 14px -14px 0 7px;
    }
    83.33% {
        -moz-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
        box-shadow: var(--color-primary) 14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) 14px 14px 0 7px;
    }
    91.67% {
        -moz-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px 14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
    100% {
        -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
        box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
            var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    }
}
/* :not(:required) hides this rule from IE9 and below */
.dotLoader:not(:required) {
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    display: inline-block;
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 100%;
    -moz-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
        var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    -webkit-box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
        var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    box-shadow: var(--color-primary) -14px -14px 0 7px, var(--color-primary-lighter) 14px -14px 0 7px,
        var(--color-secondary) 14px 14px 0 7px, var(--color-secondary-lighter) -14px 14px 0 7px;
    -moz-animation: dots-loader 3s infinite ease-in-out;
    -webkit-animation: dots-loader 3s infinite ease-in-out;
    animation: dots-loader 3s infinite ease-in-out;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
