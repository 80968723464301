.container {
    width: 366px;
    padding: 18px;
    border-radius: 6px;
    background-color: var(--color-primary);
    color: var(--color-text-on-primary);
    display: flex;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    gap: 23px;
    font-size: 16px;
    font-weight: 400;
}

.container::before {
    content: "";
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent var(--color-primary) transparent;
}
