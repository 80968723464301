.container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 35px;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.content {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 0 solid white;
    border-right: 0 solid white;
    border-left: 0 solid white;
    border-bottom: 2px solid var(--color-text-black);
    padding-left: 30px;
    font-weight: 600;
    min-height: 25px;
}
.input::placeholder {
    position: absolute !important;
    top: 0;
    left: 30px !important;
    font-weight: 600 !important;
    font-size: clamp(11px, 1.2vw, 16px) !important;
    line-height: clamp(16px, 1.2vw, 24px) !important;
    letter-spacing: 0.1em !important;
    color: var(--color-text-black) !important;
}

.input_error {
    border: 2px solid red;
}
