.label {
    color: #000000;
    font-weight: 600;
}

@media (max-width: 690px) {
    .label {
        font-size: 14px;
    }
}
