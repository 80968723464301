.container {
    position: relative;
    height: 100%;
    max-height: 30px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    align-self: flex-start;
}

.content {
    position: relative;
}

.contentInputs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.input {
    height: 100%;
    background-color: #fafbfc;
    box-shadow: inset 0 1.5px 3.5px rgba(17, 77, 130, 0.25);
    font-weight: 600;
    min-height: 30px;
    margin-right: 10px;
    text-align: center;
    color: var(--color-primary);
}

.input::placeholder {
    color: var(--color-primary);
    font-style: italic;
}

.placeholder {
    font-weight: 600 !important;
    font-size: clamp(11px, 1.2vw, 16px) !important;
    line-height: clamp(16px, 1.2vw, 24px) !important;
    letter-spacing: 0.1em !important;
    color: var(--color-text-black) !important;
}

.input_error {
    border: 2px solid red;
}

.postalCodeOrPhone {
    width: 62px !important;
}

.email {
    width: 40% !important;
}

.at {
    margin-right: 14px;
    margin-left: 14px;
    color: black !important;
    font-weight: 600;
}
