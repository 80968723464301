.container {
    max-width: 300px;
    box-shadow: 0 4px 4px rgba(0, 67, 120, 0.25);
    border-radius: 20px;
    margin-right: 15px;
    margin-left: 15px;
    padding: 1%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin-right: 15px;
    margin-left: 10px;
}

.delete {
    margin-right: 10px;
    cursor: pointer;
}

.deleteIcon {
    font-size: 14px !important;
}
