.container {
    background: #f5f6fa;
    border-radius: 2px;
    padding: 40px 30px;
    width: 100%;
    max-width: 412px;
    margin-top: 35px;
}

.title {
    font-weight: 700;
    color: var(--color-primary);
    font-size: 20px;
}

@media (max-width: 960px) {
    .actions {
        flex-direction: row;
    }
}

@media (max-width: 960px) {
    .action {
        flex-direction: column;
    }
}
