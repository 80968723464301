.container {
    margin: auto;
    width: 85%;
    overflow-y: hidden;
}

.headerContainer {
    display: flex;
    align-items: center;
    gap: 45px;
    height: 80px;
}

.title {
    color: white;
    font-weight: 600;
    margin: 0;
}

.favoriteText {
    text-decoration: underline;
    text-underline-offset: 4px;
}
.textBtn {
    color: white;
    font-weight: 600;
    cursor: pointer;
    height: 80px;

    & .icon {
        font-size: 16px !important;
    }

    & .favoriteText {
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 4px;
    }
}

.window {
    height: calc(100vh - 80px);
    max-width: 100vw;
    background-color: var(--color-primary-bg);
    border-radius: var(--border-radius);
    padding: 30px;
}

.content {
    height: calc(100vh - 50px - 40px - 30px - 30px - 80px);
    background-color: var(--color-primary-bg);
}

.galleries {
    overflow-y: auto;
    width: 100%;
    flex: 2;
    margin-right: 28px;
    padding-bottom: 50px;
}

.contentCta {
    height: 100%;
    overflow-y: auto;
}

.map {
    width: 100%;
    height: calc(100vh - 85px - 48px);
}

.CTA {
    width: 400px;
    background-color: var(--color-primary-bg);
    position: fixed;
    color: var(--color-text);
}

.contentCta::-webkit-scrollbar {
    display: none;
}

.iconContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 210px;
    margin-left: 10px;
}

.footer {
    display: none;
}

.contentCtaMobile {
    display: none;
}

@media (max-width: 960px) {
    .container {
        width: 100%;
        height: 100svh;
    }

    .headerContainer {
        height: 60px;
    }

    .title {
        display: none;
    }

    .textBtn {
        margin-left: 25px;
    }

    .content {
        height: calc(100svh - 50px - 10px - 10px - 10px - 60px);
    }

    .window {
        padding: 10px 30px;
        height: calc(100vh - 60px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .contentCta {
        display: none;
    }

    .galleries {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .contentCtaMobile {
        display: block;
        margin-top: 20px;
    }
}
