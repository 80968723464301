.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.title {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 32px;
}

.budgetContainer {
    display: flex;
    align-items: center;
    gap: 50px;
    width: 100%;
}

.amenityPreference {
    display: flex;
    margin-bottom: 32px;
    gap: 20px;
}

.amenityPreferenceRight {
    flex: 3;
}

.amenityPreferenceLeft {
    flex: 2;
}

.amenityNumber {
    margin-bottom: 17px;
}

.checkboxLabel {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textArea {
    border-color: var(--color-primary);
    padding: 23px;
    height: 144px;
    font-family: Poppins, serif;
    color: var(--color-primary);
}

.checkBoxInput {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
}

.inputInformations {
    flex: 1;
    min-width: 300px;
}

@media (max-width: 1180px) {
    .amenityPreferenceRight {
        flex: 1;
    }

    .amenityPreferenceLeft {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .container {
        gap: 40px;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
    }

    .checkBoxInput {
        gap: 20px;
    }

    .checkboxLabel {
        font-size: 12px;
    }

    .budgetContainer {
        flex-direction: column;
        gap: 30px;
        flex-wrap: wrap;
    }

    .amenityPreference {
        flex-direction: column;
        gap: 20px;
    }

    .textArea {
        font-size: 17px;
    }
}
