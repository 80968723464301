.container {
    width: 100%;
    height: 55px;
    border-top: 1px solid var(--color-primary);
    background-color: #ffffff;
}

.label {
    padding: 20px 20px 20px 50px;
    font-size: clamp(11px, 1.4vw, 14px);
}

.btn {
    font-weight: 700;
    height: 100%;
    width: 100px;
    background-color: #f3f4f5;
}

.price {
    white-space: nowrap;
    margin-left: 5px;
}

@media (max-width: 600px) {
    .label {
        padding: 5px 12px 5px 12px;
    }
}
