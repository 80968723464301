.flagDesktop,
.flagMobile {
    height: 100%;
    color: var(--color-primary);
}

.flagMobile {
    display: none;
}

.container {
    cursor: pointer;
    height: 100%;
    width: 80px;
}

.hoverHidden:hover {
    background-color: white !important;
    border-bottom: 0px solid red !important;
}

.container:hover {
    background-color: rgba(24, 40, 54, 0.5);
    border-bottom: 2px solid white;
}

.cartMenuContainerArrow {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 15px solid var(--color-secondary);
    position: absolute;
    bottom: -15px;
    right: -2px;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    transition-delay: 0.1s;
    transition-property: opacity;
}

.cartMenuContainerArrow_animate {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.cartSize {
    border-radius: 999px;
    background: var(--color-secondary);
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 67, 120, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    right: -5px;
    top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1281px) {
    .flagDesktop {
        display: none;
    }

    .flagMobile {
        display: flex;
    }

    .cartMenuContainerArrow {
        bottom: -40px;
    }
}

@media (max-width: 800px) {
    .container {
        margin-right: 60px;
    }

    .cartMenuContainerArrow {
        bottom: -50px;
    }
}
