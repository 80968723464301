.container {
    position: relative;
    background-color: #e7ecf1;
    box-shadow: inset 0 1.5px 3px rgba(17, 77, 130, 0.25);
    border-radius: 50px;
    font-size: clamp(11px, 1.3vw, 16px);
    line-height: 13px;
    width: 280px;
    height: 12%;
}

.halfSection {
    text-align: center;
    cursor: pointer;
    z-index: 2;
    width: 50%;
}

.selectedPlaceholder {
    z-index: 1;
    position: absolute;
    background-color: #ffffff;
    border: 2px solid var(--color-primary);
    border-radius: 20px;
    transition: all 0.4s ease 0s;
    height: 100%;
    width: 50%;
}

.selectedPlaceholderBis {
    transform: translateX(100%);
}

@media (max-width: 980px) {
    .container {
        margin-top: 15px !important;
        height: 100%;
        width: 200px;
    }
}
