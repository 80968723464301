.container {
    gap: 20px;
}

.chips {
    svg {
        color: white !important;
    }
}

.select {
    width: 330px;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    padding: 10px 3px 10px 15px;

    font-weight: 500 !important;
    font-family: Poppins, sans-serif !important;
    font-size: 17px;
    color: var(--color-primary) !important;

    &::before {
        border-bottom: none !important;
    }
    &::after {
        border-bottom: none !important;
    }
    &:hover::before {
        border-bottom: none !important;
    }
}
.selectedZone {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-primary);
}

.selectLabel {
    color: var(--color-primary);
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
    }

    .select {
        min-width: 290px;
        margin-right: 0;
    }

    .selectedZone {
        margin-top: 20px;
    }

    .selectLabel {
        font-size: 17px;
    }
}
