.photo,
.container {
    border-radius: 5px;
    padding: 20px;
    background-color: var(--color-thirdLvl-bg);
}

.photo {
    width: 100%;
    height: 400px;
    position: relative;
    object-fit: contain;
    object-position: center;
}

.title {
    font-weight: var(--font-weight-600) !important;
}
