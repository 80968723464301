.container {
    position: fixed;
    top: 25%;
    z-index: var(--zIndex-overlayCompare);
}

.imgContainer {
    height: 400px;
}

.content {
    position: absolute;
    width: 250px;
    left: -500px;
    border-radius: 0 10px 10px 0;
    background-color: var(--color-secondLvl-bg);
    transition: all 0.4s;
}

.isOpen {
    left: 0;
    padding: 10px;
}

.closeIcon {
    padding: 12px;
    font-size: 28px;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: var(--zIndex-overlayCompare);
    background-color: var(--color-secondLvl-bg);
    transition: all 0.4s;
}

.openIconContainer {
    border-bottom-right-radius: 0;
}

.openIcon {
    transform: rotate(180deg);
}

.img {
    margin-top: 10px;
    position: relative;
}

.link {
    cursor: pointer;
}

.link:hover {
    color: var(--color-primary-lighter);
}

@media screen and (max-width: 500px), screen and (max-height: 850px) {
    .container {
        top: 70px;
    }

    .closeIcon {
        padding: 6px;
        font-size: 24px;
    }

    .content {
        width: 180px;
    }

    .imgContainer {
        height: 300px;
    }
}
