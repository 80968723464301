.container {
    border-top: 1px solid #c0c0c0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 26px 0 34px 0;
}

.content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin: 34px 120px;
}

.btn {
    font-size: 16px;

    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s;

    display: flex;
    align-items: center;
    gap: 20px;
}

.btn:disabled {
    background-color: var(--color-inactive-bg);
    border: 1px solid var(--color-inactive-bg);
    cursor: not-allowed;
}

.btnPrimary {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: var(--color-text-on-primary) !important;
    & a {
        color: var(--color-text-on-primary) !important;
    }
}

.btnOutlined {
    background-color: transparent;
    border: 1px solid var(--color-inactive-bg);
    color: var(--color-inactive-bg) !important;
    & a {
        color: var(--color-inactive-bg) !important;
    }
    & svg {
        color: var(--color-inactive-bg);
    }
}

@media (max-width: 1000px) {
    .content {
        margin: 34px 60px;
    }
}

@media (max-width: 768px) {
    .container {
        margin: 20px 5px 20px 5px;
    }

    .content {
        margin: 34px 0;
    }
}
