.answer {
    color: black;
    font-size: 17px;
    margin: 11px 0 39px 0;
}

.agreementContainer {
    margin-top: 50px;
    gap: 10px;

    & div:first-child {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
    }

    & div:last-child {
        font-size: 17px;
        font-weight: 600;
    }
}
