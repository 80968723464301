.card {
    background-color: white;
    height: 230px;
    box-shadow: 0 4px 4px 0 rgba(17, 77, 130, 0.25);
    color: var(--color-primary);
}

.img {
    height: 100%;
    object-fit: cover;
}

.radius {
    position: absolute;
    right: 0;
    top: 40px;
    height: 130px;
    margin-top: 11px;
    margin-right: 8px;
}

.content {
    padding: 12px 4px 12px 12px;
    width: 100%;
}

.title {
    line-height: 1.3;
    font-size: 21px;
    font-weight: 700;
    width: 100%;
}

.address {
    font-size: 15px;
}

@media (max-width: 960px) {
    .card {
        height: 200px;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
    }
}
