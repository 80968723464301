.container {
    height: 180px;
    display: block;
    position: relative;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.content {
    z-index: 1;
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin-top: auto;
    color: white;
    max-width: 75%;
}

.img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.price {
    font-size: 14px;
}
