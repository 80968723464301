.input {
    border-radius: var(--border-radius);
    border: solid var(--color-border) 1px;
    font-size: 15px;
    box-shadow: 0px 2.46575px 2.46575px rgba(17, 77, 130, 0.25);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 45px;
    width: 100%;
}

.input > form-control > .rdtPicker {
    right: 0;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
}

.label {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
}

.arrowContent {
    width: 45.62px;
    background: #f3f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.datetime {
    width: 100%;
    display: flex;
    justify-content: center;
}
