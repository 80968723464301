.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.content {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    background-color: #fafbfc;
    box-shadow: inset 0 1.664px 3.328px rgba(17, 77, 130, 0.25);
    font-weight: 600;
    min-height: 30px;
    padding-left: 10px;
    color: var(--color-primary);
}

.required {
    color: var(--color-error);
}

.placeholder {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: clamp(16px, 1.2vw, 24px) !important;
    letter-spacing: 0.1em !important;
    color: #000000 !important;
}

.input_error {
    border: 2px solid red;
}
