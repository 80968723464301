.favoriteButton,
.iconButtonFavorite {
    z-index: 5;
    right: 0;
    font-size: 26px !important;
}

.favoriteButton {
    color: var(--color-primary) !important;
}

.iconButtonFavorite {
    position: absolute !important;
    color: white !important;
}

.favoriteTopBar {
    right: unset;
}

.primaryColors {
    color: var(--color-primary) !important;
    position: relative !important;
}
