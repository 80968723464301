.header {
    width: 100%;
}

.headerContentImg {

}

.headerImg {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.breadcrumb {
    margin: auto;
    max-width: 900px;
}

.blogContainer {
    width: 100%;
    padding-bottom: 60px;
}

.blogContent {
    margin: auto;
    max-width: 900px;
}

.blogDates {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-primary);
}

@media (max-width: 1280px) {
    .headerImg {
        height: 400px;
    }
}

@media (max-width: 1000px) {
    .headerImg {
        height: 300px;
    }
}

@media (max-width: 600px) {
    .headerImg {
        height: 240px;
    }
}
