.container {
    position: fixed;
    width: 100%;
    bottom: 20px;
    z-index: 10;
}

@media (min-width: 600px) {
    .container {
        display: none;
    }
}
