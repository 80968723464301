.input {
    width: 35px;
    height: 27px;
    text-align: center;
    box-shadow: inset 0 1.5px 3px rgba(17, 77, 130, 0.25);
    background-color: var(--color-grey-payment);
    color: var(--color-primary);
    font-size: 14px;
}

.arrowBlock {
    cursor: pointer;
    height: 50%;
    width: 20px;
    background-color: var(--color-grey-payment);
    box-shadow: 0 0 1px rgba(17, 77, 130, 0.25);
}

.arrows {
}

.content {
    height: 27px;
    display: flex;
}
