.container {
    bottom: 0;
    right: 0;
    background-color: var(--color-primary);
    z-index: var(--zIndex-virtualAssistant--popup);
    color: white;
    padding: 45px 45px 35px 35px;
    width: 400px;

    display: flex;
    flex-direction: row;
    transition: 0.4s all;

    opacity: 1;
    bottom: 0;
}

.hidden {
    opacity: 0;
    bottom: -100%;
}

.toRight {
    right: 0;
    border-top-left-radius: 10px;
}

.toLeft {
    left: 0;
    border-top-right-radius: 10px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.close {
    right: 20px;
    top: 45px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .container {
        margin: 2%;
        width: 96%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        text-align: center;
        padding: 40px 20px 20px 20px;
    }

    .toRight {
        border-radius: 5px !important;
    }

    .toLeft {
        border-radius: 5px !important;
    }

    .ctaContainer  {
        justify-content: center;
    }

    .close {
        right: 10px;
        top: 15px;
        cursor: pointer;
    }

}
