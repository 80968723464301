.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bullet {
    border: 3px solid #ffc435;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: white;
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullet:first-child {
    margin-left: 30px;
}

.bullet_last {
    margin-right: 30px;
}

.content {
    width: 300px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.line {
    position: absolute;
    border-bottom: 3px solid #ffc435;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
}

.currentItem {
    height: 10px;
    width: 10px;
    background-color: #ffc435;
    border-radius: 50%;
}
