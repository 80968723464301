.container {
    position: relative;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    aspect-ratio: 1 / 1;
}

.borderYellow {
    border: 2px solid #ffc435;
}

.container:hover {
    -webkit-box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.26);
    cursor: pointer;
}

.containerFeatured {
    width: 100%;
    max-width: 400px;
}

.containerMap {
    margin: 0 !important;
    padding: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0 !important;
    border-radius: 2px !important;
    width: 260px;
    /*height: 220px;*/
    font-size: var(--font-size-property-card);
    font-weight: 500;
    aspect-ratio: unset;
}

.aspectBuilder {
    aspect-ratio: 3 / 4;
}

.containerMap:hover {
    margin: 0;
    transform: unset;
    cursor: pointer;
}

.containerGallery {
    max-height: calc(100% - 150px);
    overflow-y: hidden;
}

.containerGalleryMap {
    max-height: 130px;
}

@media (max-width: 600px) {
    .container {
        min-height: unset;
        margin: 10px 0;
    }

    .containerMap {
        width: 200px;
    }
}
