.container {
    min-height: 100vh;
    z-index: var(--zIndex-cookieBannerModal) !important;
    background-color: var(--color-primary) !important;
    padding: 80px 50px 50px;

    & h2 {
        margin-top: 1rem;
    }

    & p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: white;
        padding-left: 0;
        padding-right: 0;
    }
}

.text {
    color: white;

    & h2 {
        color: white;
    }
}
