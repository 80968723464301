.container {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    z-index: var(--zIndex-navBar);
    transition-duration: 0.5s;
    transition-timing-function: ease;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
}

.display {
    top: 0;
}

.text {
    font-size: 17px;
}

.favoriteButton {
    border: none !important;
    color: var(--color-primary) !important;
}

@media (max-width: 600px) {
    .favoriteButton {
        display: none !important;
    }
}
