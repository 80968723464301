.container {
    border: 2px solid white;
    border-radius: 4px;
    background-color: white;
    color: var(--color-primary);
    text-align: center;
    white-space: nowrap;
    padding: 5px 30px;
    display: flex;
    justify-content: center;
}

.contained {
    border: 2px solid white;
    background-color: var(--color-primary);
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.outlined {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    font-size: 17px;
    padding: 5px 20px;
}

.fit {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.small {
    font-weight: 400;
    font-size: 12px;
    padding: 5px 10px;
}

.medium {
    font-weight: 500;
    font-size: 14px;
    padding: 5px 20px;
}

.primaryOutlined {
    border: 2px solid var(--color-primary);
    background-color: transparent;
    color: var(--color-primary);
}


.primaryOutlined:hover {
    border: 2px solid var(--color-primary-darker);
    color: var(--color-primary-darker);
}

.primaryContained {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    color: white;
}

.primaryContained:hover {
    border: 2px solid var(--color-primary-darker);
    background-color: var(--color-primary-darker);
}

.secondaryOutlined {
    border: 2px solid var(--color-secondary);
    background-color: white;
    color: var(--color-secondary);
}

.secondaryContained {
    border: 2px solid var(--color-secondary);
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

@media (max-width: 780px) {
    .contained {
        font-size: 16px;
    }

    .outlined {
        font-size: 13px;
    }
}
