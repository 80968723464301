@font-face {
    font-family: "icomoon";
    src: url(/static/assets/icomoon.4832e24e.ttf);
    src: url(/static/assets/icomoon.4832e24e.ttf) format("truetype"),
        url(/static/assets/icomoon.1956237a.woff) format("woff"),
        url(/static/assets/icomoon.27d51a7b.svg#icomoon) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-amazonaws:before {
    content: "\e91f";
}

.icon-css3:before {
    content: "\e9ab";
    color: #1572b6;
}

.icon-debian:before {
    content: "\e9b5";
    color: #a81d33;
}

.icon-docker:before {
    content: "\e9cc";
    color: #1488c6;
}

.icon-electron:before {
    content: "\e9e0";
    color: #47848f;
}

.icon-html5:before {
    content: "\ea6c";
    color: #e34f26;
}

.icon-javascript:before {
    content: "\ea94";
    color: #f7df1e;
}

.icon-laravel:before {
    content: "\eab7";
    color: #ff2d20;
}

.icon-mongo:before {
    content: "\eb0a";
    color: #47a248;
}

.icon-mysql:before {
    content: "\eb15";
    color: #4479a1;
}

.icon-node:before {
    content: "\eb24";
    color: #393;
}

.icon-nuxt:before {
    content: "\eb2e";
    color: #00c58e;
}

.icon-paypal:before {
    content: "\eb50";
    color: #00457c;
}

.icon-php:before {
    content: "\eb53";
    color: #777bb4;
}

.icon-react:before {
    content: "\eb8d";
    color: #61dafb;
}

.icon-square:before {
    content: "\ebee";
}

.icon-stripe:before {
    content: "\ec04";
    color: #008cdd;
}

.icon-symfony:before {
    content: "\ec14";
}

.icon-typescript:before {
    content: "\ec40";
    color: #007acc;
}

.icon-ubuntu:before {
    content: "\ec46";
    color: #e95420;
}

.icon-vue:before {
    content: "\ec65";
    color: #4fc08d;
}

.icon-windows:before {
    content: "\ec76";
    color: #0078d6;
}

.icon-activity:before {
    content: "\eca6";
}

.icon-home:before {
    content: "\eca7";
}

.icon-home2:before {
    content: "\eca8";
}

.icon-home3:before {
    content: "\eca9";
}

.icon-office:before {
    content: "\ecaa";
}

.icon-newspaper:before {
    content: "\ecab";
}

.icon-pencil1:before {
    content: "\ecac";
}

.icon-pencil2:before {
    content: "\ecad";
}

.icon-quill:before {
    content: "\ecae";
}

.icon-pen:before {
    content: "\ecaf";
}

.icon-blog:before {
    content: "\ecb0";
}

.icon-eyedropper:before {
    content: "\ecb1";
}

.icon-droplet:before {
    content: "\ecb2";
}

.icon-paint-format:before {
    content: "\ecb3";
}

.icon-image:before {
    content: "\ecb4";
}

.icon-images:before {
    content: "\ecb5";
}

.icon-camera:before {
    content: "\ecb6";
}

.icon-headphones:before {
    content: "\ecb7";
}

.icon-music:before {
    content: "\ecb8";
}

.icon-play:before {
    content: "\ecb9";
}

.icon-film:before {
    content: "\ecba";
}

.icon-video-camera:before {
    content: "\ecbb";
}

.icon-dice:before {
    content: "\ecbc";
}

.icon-pacman:before {
    content: "\ecbd";
}

.icon-spades:before {
    content: "\ecbe";
}

.icon-clubs:before {
    content: "\ecbf";
}

.icon-diamonds:before {
    content: "\ecc0";
}

.icon-bullhorn:before {
    content: "\ecc1";
}

.icon-connection:before {
    content: "\ecc2";
}

.icon-podcast:before {
    content: "\ecc3";
}

.icon-feed:before {
    content: "\ecc4";
}

.icon-mic:before {
    content: "\ecc5";
}

.icon-book:before {
    content: "\ecc6";
}

.icon-books:before {
    content: "\ecc7";
}

.icon-library:before {
    content: "\ecc8";
}

.icon-file-text:before {
    content: "\ecc9";
}

.icon-profile:before {
    content: "\ecca";
}

.icon-file-empty:before {
    content: "\eccb";
}

.icon-files-empty:before {
    content: "\eccc";
}

.icon-file-text2:before {
    content: "\eccd";
}

.icon-file-picture:before {
    content: "\ecce";
}

.icon-file-music:before {
    content: "\eccf";
}

.icon-file-play:before {
    content: "\ecd0";
}

.icon-file-video:before {
    content: "\ecd1";
}

.icon-file-zip:before {
    content: "\ecd2";
}

.icon-copy:before {
    content: "\ecd3";
}

.icon-paste:before {
    content: "\ecd4";
}

.icon-stack:before {
    content: "\ecd5";
}

.icon-folder:before {
    content: "\ecd6";
}

.icon-folder-open:before {
    content: "\ecd7";
}

.icon-folder-plus:before {
    content: "\ecd8";
}

.icon-folder-minus:before {
    content: "\ecd9";
}

.icon-folder-download:before {
    content: "\ecda";
}

.icon-folder-upload:before {
    content: "\ecdb";
}

.icon-price-tag:before {
    content: "\ecdc";
}

.icon-price-tags:before {
    content: "\ecdd";
}

.icon-barcode:before {
    content: "\ecde";
}

.icon-qrcode:before {
    content: "\ecdf";
}

.icon-ticket:before {
    content: "\ece0";
}

.icon-cart:before {
    content: "\ece1";
}

.icon-coin-dollar:before {
    content: "\ece2";
}

.icon-coin-euro:before {
    content: "\ece3";
}

.icon-coin-pound:before {
    content: "\ece4";
}

.icon-coin-yen:before {
    content: "\ece5";
}

.icon-credit-card:before {
    content: "\ece6";
}

.icon-calculator:before {
    content: "\ece7";
}

.icon-lifebuoy:before {
    content: "\ece8";
}

.icon-phone:before {
    content: "\ece9";
}

.icon-phone-hang-up:before {
    content: "\ecea";
}

.icon-address-book:before {
    content: "\eceb";
}

.icon-envelop:before {
    content: "\ecec";
}

.icon-pushpin:before {
    content: "\eced";
}

.icon-location:before {
    content: "\ecee";
}

.icon-location2:before {
    content: "\ecef";
}

.icon-compass:before {
    content: "\ecf0";
}

.icon-compass2:before {
    content: "\ecf1";
}

.icon-map:before {
    content: "\ecf2";
}

.icon-map2:before {
    content: "\ecf3";
}

.icon-history:before {
    content: "\ecf4";
}

.icon-clock:before {
    content: "\ecf5";
}

.icon-clock2:before {
    content: "\ecf6";
}

.icon-alarm:before {
    content: "\ecf7";
}

.icon-bell:before {
    content: "\ecf8";
}

.icon-stopwatch:before {
    content: "\ecf9";
}

.icon-calendar:before {
    content: "\ecfa";
}

.icon-printer:before {
    content: "\ecfb";
}

.icon-keyboard:before {
    content: "\ecfc";
}

.icon-display:before {
    content: "\ecfd";
}

.icon-laptop:before {
    content: "\ecfe";
}

.icon-mobile:before {
    content: "\ecff";
}

.icon-mobile2:before {
    content: "\ed00";
}

.icon-tablet:before {
    content: "\ed01";
}

.icon-tv:before {
    content: "\ed02";
}

.icon-drawer:before {
    content: "\ed03";
}

.icon-drawer2:before {
    content: "\ed04";
}

.icon-box-add:before {
    content: "\ed05";
}

.icon-box-remove:before {
    content: "\ed06";
}

.icon-download:before {
    content: "\ed07";
}

.icon-upload:before {
    content: "\ed08";
}

.icon-floppy-disk:before {
    content: "\ed09";
}

.icon-drive:before {
    content: "\ed0a";
}

.icon-database:before {
    content: "\ed0b";
}

.icon-undo:before {
    content: "\ed0c";
}

.icon-redo:before {
    content: "\ed0d";
}

.icon-undo2:before {
    content: "\ed0e";
}

.icon-redo2:before {
    content: "\ed0f";
}

.icon-forward:before {
    content: "\ed10";
}

.icon-reply:before {
    content: "\ed11";
}

.icon-bubble:before {
    content: "\ed12";
}

.icon-bubbles:before {
    content: "\ed13";
}

.icon-bubbles2:before {
    content: "\ed14";
}

.icon-bubble2:before {
    content: "\ed15";
}

.icon-bubbles3:before {
    content: "\ed16";
}

.icon-bubbles4:before {
    content: "\ed17";
}

.icon-user:before {
    content: "\ed18";
}

.icon-users:before {
    content: "\ed19";
}

.icon-user-plus:before {
    content: "\ed1a";
}

.icon-user-minus:before {
    content: "\ed1b";
}

.icon-user-check:before {
    content: "\ed1c";
}

.icon-user-tie:before {
    content: "\ed1d";
}

.icon-quotes-left:before {
    content: "\ed1e";
}

.icon-quotes-right:before {
    content: "\ed1f";
}

.icon-hour-glass:before {
    content: "\ed20";
}

.icon-spinner:before {
    content: "\ed21";
}

.icon-spinner2:before {
    content: "\ed22";
}

.icon-spinner3:before {
    content: "\ed23";
}

.icon-spinner4:before {
    content: "\ed24";
}

.icon-spinner5:before {
    content: "\ed25";
}

.icon-spinner6:before {
    content: "\ed26";
}

.icon-spinner7:before {
    content: "\ed27";
}

.icon-spinner8:before {
    content: "\ed28";
}

.icon-spinner9:before {
    content: "\ed29";
}

.icon-spinner10:before {
    content: "\ed2a";
}

.icon-spinner11:before {
    content: "\ed2b";
}

.icon-binoculars:before {
    content: "\ed2c";
}

.icon-search:before {
    content: "\ed2d";
}

.icon-zoom-in:before {
    content: "\ed2e";
}

.icon-zoom-out:before {
    content: "\ed2f";
}

.icon-enlarge:before {
    content: "\ed30";
}

.icon-shrink:before {
    content: "\ed31";
}

.icon-enlarge2:before {
    content: "\ed32";
}

.icon-shrink2:before {
    content: "\ed33";
}

.icon-key:before {
    content: "\ed34";
}

.icon-key2:before {
    content: "\ed35";
}

.icon-lock:before {
    content: "\ed36";
}

.icon-unlocked:before {
    content: "\ed37";
}

.icon-wrench:before {
    content: "\ed38";
}

.icon-equalizer:before {
    content: "\ed39";
}

.icon-equalizer2:before {
    content: "\ed3a";
}

.icon-cog:before {
    content: "\ed3b";
}

.icon-cogs:before {
    content: "\ed3c";
}

.icon-hammer:before {
    content: "\ed3d";
}

.icon-magic-wand:before {
    content: "\ed3e";
}

.icon-aid-kit:before {
    content: "\ed3f";
}

.icon-bug:before {
    content: "\ed40";
}

.icon-pie-chart:before {
    content: "\ed41";
}

.icon-stats-dots:before {
    content: "\ed42";
}

.icon-stats-bars:before {
    content: "\ed43";
}

.icon-stats-bars2:before {
    content: "\ed44";
}

.icon-trophy:before {
    content: "\ed45";
}

.icon-gift:before {
    content: "\ed46";
}

.icon-glass:before {
    content: "\ed47";
}

.icon-glass2:before {
    content: "\ed48";
}

.icon-mug:before {
    content: "\ed49";
}

.icon-spoon-knife:before {
    content: "\ed4a";
}

.icon-leaf:before {
    content: "\ed4b";
}

.icon-rocket:before {
    content: "\ed4c";
}

.icon-meter:before {
    content: "\ed4d";
}

.icon-meter2:before {
    content: "\ed4e";
}

.icon-hammer2:before {
    content: "\ed4f";
}

.icon-fire:before {
    content: "\ed50";
}

.icon-lab:before {
    content: "\ed51";
}

.icon-magnet:before {
    content: "\ed52";
}

.icon-bin:before {
    content: "\ed53";
}

.icon-bin2:before {
    content: "\ed54";
}

.icon-briefcase:before {
    content: "\ed55";
}

.icon-airplane:before {
    content: "\ed56";
}

.icon-truck:before {
    content: "\ed57";
}

.icon-road:before {
    content: "\ed58";
}

.icon-accessibility:before {
    content: "\ed59";
}

.icon-target:before {
    content: "\ed5a";
}

.icon-shield:before {
    content: "\ed5b";
}

.icon-power:before {
    content: "\ed5c";
}

.icon-switch:before {
    content: "\ed5d";
}

.icon-power-cord:before {
    content: "\ed5e";
}

.icon-clipboard:before {
    content: "\ed5f";
}

.icon-list-numbered:before {
    content: "\ed60";
}

.icon-list:before {
    content: "\ed61";
}

.icon-list2:before {
    content: "\ed62";
}

.icon-tree:before {
    content: "\ed63";
}

.icon-menu:before {
    content: "\ed64";
}

.icon-menu2:before {
    content: "\ed65";
}

.icon-menu3:before {
    content: "\ed66";
}

.icon-menu4:before {
    content: "\ed67";
}

.icon-cloud:before {
    content: "\ed68";
}

.icon-cloud-download:before {
    content: "\ed69";
}

.icon-cloud-upload:before {
    content: "\ed6a";
}

.icon-cloud-check:before {
    content: "\ed6b";
}

.icon-download2:before {
    content: "\ed6c";
}

.icon-upload2:before {
    content: "\ed6d";
}

.icon-download3:before {
    content: "\ed6e";
}

.icon-upload3:before {
    content: "\ed6f";
}

.icon-sphere:before {
    content: "\ed70";
}

.icon-earth:before {
    content: "\ed71";
}

.icon-link:before {
    content: "\ed72";
}

.icon-flag:before {
    content: "\ed73";
}

.icon-attachment:before {
    content: "\ed74";
}

.icon-eye:before {
    content: "\ed75";
}

.icon-eye-plus:before {
    content: "\ed76";
}

.icon-eye-minus:before {
    content: "\ed77";
}

.icon-eye-blocked:before {
    content: "\ed78";
}

.icon-bookmark:before {
    content: "\ed79";
}

.icon-bookmarks:before {
    content: "\ed7a";
}

.icon-sun:before {
    content: "\ed7b";
}

.icon-contrast:before {
    content: "\ed7c";
}

.icon-brightness-contrast:before {
    content: "\ed7d";
}

.icon-star-empty:before {
    content: "\ed7e";
}

.icon-star-half:before {
    content: "\ed7f";
}

.icon-star-full:before {
    content: "\ed80";
}

.icon-heart:before {
    content: "\ed81";
}

.icon-heart-broken:before {
    content: "\ed82";
}

.icon-man:before {
    content: "\ed83";
}

.icon-woman:before {
    content: "\ed84";
}

.icon-man-woman:before {
    content: "\ed85";
}

.icon-happy:before {
    content: "\ed86";
}

.icon-happy2:before {
    content: "\ed87";
}

.icon-smile:before {
    content: "\ed88";
}

.icon-smile2:before {
    content: "\ed89";
}

.icon-tongue:before {
    content: "\ed8a";
}

.icon-tongue2:before {
    content: "\ed8b";
}

.icon-sad:before {
    content: "\ed8c";
}

.icon-sad2:before {
    content: "\ed8d";
}

.icon-wink:before {
    content: "\ed8e";
}

.icon-wink2:before {
    content: "\ed8f";
}

.icon-grin:before {
    content: "\ed90";
}

.icon-grin2:before {
    content: "\ed91";
}

.icon-cool:before {
    content: "\ed92";
}

.icon-cool2:before {
    content: "\ed93";
}

.icon-angry:before {
    content: "\ed94";
}

.icon-angry2:before {
    content: "\ed95";
}

.icon-evil:before {
    content: "\ed96";
}

.icon-evil2:before {
    content: "\ed97";
}

.icon-shocked:before {
    content: "\ed98";
}

.icon-shocked2:before {
    content: "\ed99";
}

.icon-baffled:before {
    content: "\ed9a";
}

.icon-baffled2:before {
    content: "\ed9b";
}

.icon-confused:before {
    content: "\ed9c";
}

.icon-confused2:before {
    content: "\ed9d";
}

.icon-neutral:before {
    content: "\ed9e";
}

.icon-neutral2:before {
    content: "\ed9f";
}

.icon-hipster:before {
    content: "\eda0";
}

.icon-hipster2:before {
    content: "\eda1";
}

.icon-wondering:before {
    content: "\eda2";
}

.icon-wondering2:before {
    content: "\eda3";
}

.icon-sleepy:before {
    content: "\eda4";
}

.icon-sleepy2:before {
    content: "\eda5";
}

.icon-frustrated:before {
    content: "\eda6";
}

.icon-frustrated2:before {
    content: "\eda7";
}

.icon-crying:before {
    content: "\eda8";
}

.icon-crying2:before {
    content: "\eda9";
}

.icon-point-up:before {
    content: "\edaa";
}

.icon-point-right:before {
    content: "\edab";
}

.icon-point-down:before {
    content: "\edac";
}

.icon-point-left:before {
    content: "\edad";
}

.icon-warning:before {
    content: "\edae";
}

.icon-notification:before {
    content: "\edaf";
}

.icon-question:before {
    content: "\edb0";
}

.icon-plus:before {
    content: "\edb1";
}

.icon-minus:before {
    content: "\edb2";
}

.icon-info:before {
    content: "\edb3";
}

.icon-cancel-circle:before {
    content: "\edb4";
}

.icon-blocked:before {
    content: "\edb5";
}

.icon-cross:before {
    content: "\edb6";
}

.icon-checkmark:before {
    content: "\edb7";
}

.icon-checkmark2:before {
    content: "\edb8";
}

.icon-spell-check:before {
    content: "\edb9";
}

.icon-enter:before {
    content: "\edba";
}

.icon-exit:before {
    content: "\edbb";
}

.icon-play2:before {
    content: "\edbc";
}

.icon-pause:before {
    content: "\edbd";
}

.icon-stop:before {
    content: "\edbe";
}

.icon-previous:before {
    content: "\edbf";
}

.icon-next:before {
    content: "\edc0";
}

.icon-backward:before {
    content: "\edc1";
}

.icon-forward2:before {
    content: "\edc2";
}

.icon-play3:before {
    content: "\edc3";
}

.icon-pause2:before {
    content: "\edc4";
}

.icon-stop2:before {
    content: "\edc5";
}

.icon-backward2:before {
    content: "\edc6";
}

.icon-forward3:before {
    content: "\edc7";
}

.icon-first:before {
    content: "\edc8";
}

.icon-last:before {
    content: "\edc9";
}

.icon-previous2:before {
    content: "\edca";
}

.icon-next2:before {
    content: "\edcb";
}

.icon-eject:before {
    content: "\edcc";
}

.icon-volume-high:before {
    content: "\edcd";
}

.icon-volume-medium:before {
    content: "\edce";
}

.icon-volume-low:before {
    content: "\edcf";
}

.icon-volume-mute:before {
    content: "\edd0";
}

.icon-volume-mute2:before {
    content: "\edd1";
}

.icon-volume-increase:before {
    content: "\edd2";
}

.icon-volume-decrease:before {
    content: "\edd3";
}

.icon-loop1:before {
    content: "\edd4";
}

.icon-loop2:before {
    content: "\edd5";
}

.icon-infinite:before {
    content: "\edd6";
}

.icon-shuffle:before {
    content: "\edd7";
}

.icon-arrow-up-left:before {
    content: "\edd8";
}

.icon-arrow-up:before {
    content: "\edd9";
}

.icon-arrow-up-right:before {
    content: "\edda";
}

.icon-arrow-right:before {
    content: "\eddb";
}

.icon-arrow-down-right:before {
    content: "\eddc";
}

.icon-arrow-down:before {
    content: "\eddd";
}

.icon-arrow-down-left:before {
    content: "\edde";
}

.icon-arrow-left:before {
    content: "\eddf";
}

.icon-arrow-up-left2:before {
    content: "\ede0";
}

.icon-arrow-up2:before {
    content: "\ede1";
}

.icon-arrow-up-right2:before {
    content: "\ede2";
}

.icon-arrow-right2:before {
    content: "\ede3";
}

.icon-arrow-down-right2:before {
    content: "\ede4";
}

.icon-arrow-down2:before {
    content: "\ede5";
}

.icon-arrow-left2:before {
    content: "\ede6";
}

.icon-arrow-down-left2:before {
    content: "\ede7";
}

.icon-circle-up:before {
    content: "\ede8";
}

.icon-circle-right:before {
    content: "\ede9";
}

.icon-circle-down:before {
    content: "\edea";
}

.icon-circle-left:before {
    content: "\edeb";
}

.icon-tab:before {
    content: "\edec";
}

.icon-move-up:before {
    content: "\eded";
}

.icon-move-down:before {
    content: "\edee";
}

.icon-sort-alpha-asc:before {
    content: "\edef";
}

.icon-sort-alpha-desc:before {
    content: "\edf0";
}

.icon-sort-numeric-asc:before {
    content: "\edf1";
}

.icon-sort-numberic-desc:before {
    content: "\edf2";
}

.icon-sort-amount-asc:before {
    content: "\edf3";
}

.icon-sort-amount-desc:before {
    content: "\edf4";
}

.icon-command:before {
    content: "\edf5";
}

.icon-shift:before {
    content: "\edf6";
}

.icon-ctrl:before {
    content: "\edf7";
}

.icon-opt:before {
    content: "\edf8";
}

.icon-checkbox-checked:before {
    content: "\edf9";
}

.icon-checkbox-unchecked:before {
    content: "\edfa";
}

.icon-radio-checked:before {
    content: "\edfb";
}

.icon-radio-checked2:before {
    content: "\edfc";
}

.icon-radio-unchecked:before {
    content: "\edfd";
}

.icon-crop:before {
    content: "\edfe";
}

.icon-make-group:before {
    content: "\edff";
}

.icon-ungroup:before {
    content: "\ee00";
}

.icon-scissors:before {
    content: "\ee01";
}

.icon-filter:before {
    content: "\ee02";
}

.icon-font:before {
    content: "\ee03";
}

.icon-ligature:before {
    content: "\ee04";
}

.icon-ligature2:before {
    content: "\ee05";
}

.icon-text-height:before {
    content: "\ee06";
}

.icon-text-width:before {
    content: "\ee07";
}

.icon-font-size:before {
    content: "\ee08";
}

.icon-bold:before {
    content: "\ee09";
}

.icon-underline:before {
    content: "\ee0a";
}

.icon-italic:before {
    content: "\ee0b";
}

.icon-strikethrough:before {
    content: "\ee0c";
}

.icon-omega:before {
    content: "\ee0d";
}

.icon-sigma:before {
    content: "\ee0e";
}

.icon-page-break:before {
    content: "\ee0f";
}

.icon-superscript:before {
    content: "\ee10";
}

.icon-subscript:before {
    content: "\ee11";
}

.icon-superscript2:before {
    content: "\ee12";
}

.icon-subscript2:before {
    content: "\ee13";
}

.icon-text-color:before {
    content: "\ee14";
}

.icon-pagebreak:before {
    content: "\ee15";
}

.icon-clear-formatting:before {
    content: "\ee16";
}

.icon-table:before {
    content: "\ee17";
}

.icon-table2:before {
    content: "\ee18";
}

.icon-insert-template:before {
    content: "\ee19";
}

.icon-pilcrow:before {
    content: "\ee1a";
}

.icon-ltr:before {
    content: "\ee1b";
}

.icon-rtl:before {
    content: "\ee1c";
}

.icon-section:before {
    content: "\ee1d";
}

.icon-paragraph-left:before {
    content: "\ee1e";
}

.icon-paragraph-center:before {
    content: "\ee1f";
}

.icon-paragraph-right:before {
    content: "\ee20";
}

.icon-paragraph-justify:before {
    content: "\ee21";
}

.icon-indent-increase:before {
    content: "\ee22";
}

.icon-indent-decrease:before {
    content: "\ee23";
}

.icon-share:before {
    content: "\ee24";
}

.icon-new-tab:before {
    content: "\ee25";
}

.icon-embed:before {
    content: "\ee26";
}

.icon-embed2:before {
    content: "\ee27";
}

.icon-terminal:before {
    content: "\ee28";
}

.icon-share2:before {
    content: "\ee29";
}

.icon-mail:before {
    content: "\ee2a";
}

.icon-mail2:before {
    content: "\ee2b";
}

.icon-mail3:before {
    content: "\ee2c";
}

.icon-mail4:before {
    content: "\ee2d";
}

.icon-amazon1:before {
    content: "\ee2e";
}

.icon-google1:before {
    content: "\ee2f";
}

.icon-google2:before {
    content: "\ee30";
}

.icon-google3:before {
    content: "\ee31";
}

.icon-google-plus:before {
    content: "\ee32";
}

.icon-google-plus2:before {
    content: "\ee33";
}

.icon-google-plus3:before {
    content: "\ee34";
}

.icon-hangouts:before {
    content: "\ee35";
}

.icon-google-drive:before {
    content: "\ee36";
}

.icon-facebook1:before {
    content: "\ee37";
}

.icon-facebook2:before {
    content: "\ee38";
}

.icon-instagram1:before {
    content: "\ee39";
}

.icon-whatsapp1:before {
    content: "\ee3a";
}

.icon-spotify1:before {
    content: "\ee3b";
}

.icon-telegram1:before {
    content: "\ee3c";
}

.icon-twitter1:before {
    content: "\ee3d";
}

.icon-vine1:before {
    content: "\ee3e";
}

.icon-vk1:before {
    content: "\ee3f";
}

.icon-renren1:before {
    content: "\ee40";
}

.icon-sina-weibo:before {
    content: "\ee41";
}

.icon-rss1:before {
    content: "\ee42";
}

.icon-rss2:before {
    content: "\ee43";
}

.icon-youtube1:before {
    content: "\ee44";
}

.icon-youtube2:before {
    content: "\ee45";
}

.icon-twitch1:before {
    content: "\ee46";
}

.icon-vimeo1:before {
    content: "\ee47";
}

.icon-vimeo2:before {
    content: "\ee48";
}

.icon-lanyrd:before {
    content: "\ee49";
}

.icon-flickr1:before {
    content: "\ee4a";
}

.icon-flickr2:before {
    content: "\ee4b";
}

.icon-flickr3:before {
    content: "\ee4c";
}

.icon-flickr4:before {
    content: "\ee4d";
}

.icon-dribbble1:before {
    content: "\ee4e";
}

.icon-behance1:before {
    content: "\ee4f";
}

.icon-behance2:before {
    content: "\ee50";
}

.icon-deviantart1:before {
    content: "\ee51";
}

.icon-500px1:before {
    content: "\ee52";
}

.icon-steam1:before {
    content: "\ee53";
}

.icon-steam2:before {
    content: "\ee54";
}

.icon-dropbox1:before {
    content: "\ee55";
}

.icon-onedrive:before {
    content: "\ee56";
}

.icon-github1:before {
    content: "\ee57";
}

.icon-npm1:before {
    content: "\ee58";
}

.icon-basecamp1:before {
    content: "\ee59";
}

.icon-trello1:before {
    content: "\ee5a";
}

.icon-wordpress1:before {
    content: "\ee5b";
}

.icon-joomla1:before {
    content: "\ee5c";
}

.icon-ello1:before {
    content: "\ee5d";
}

.icon-blogger1:before {
    content: "\ee5e";
}

.icon-blogger2:before {
    content: "\ee5f";
}

.icon-tumblr1:before {
    content: "\ee60";
}

.icon-tumblr2:before {
    content: "\ee61";
}

.icon-yahoo1:before {
    content: "\ee62";
}

.icon-yahoo2:before {
    content: "\ee63";
}

.icon-tux:before {
    content: "\ee64";
}

.icon-appleinc:before {
    content: "\ee65";
}

.icon-finder:before {
    content: "\ee66";
}

.icon-android1:before {
    content: "\ee67";
}

.icon-windows1:before {
    content: "\ee68";
}

.icon-windows8:before {
    content: "\ee69";
}

.icon-soundcloud1:before {
    content: "\ee6a";
}

.icon-soundcloud2:before {
    content: "\ee6b";
}

.icon-skype1:before {
    content: "\ee6c";
}

.icon-reddit1:before {
    content: "\ee6d";
}

.icon-hackernews:before {
    content: "\ee6e";
}

.icon-wikipedia1:before {
    content: "\ee6f";
}

.icon-linkedin1:before {
    content: "\ee70";
}

.icon-linkedin2:before {
    content: "\ee71";
}

.icon-lastfm:before {
    content: "\ee72";
}

.icon-lastfm2:before {
    content: "\ee73";
}

.icon-delicious1:before {
    content: "\ee74";
}

.icon-stumbleupon:before {
    content: "\ee75";
}

.icon-stumbleupon2:before {
    content: "\ee76";
}

.icon-stackoverflow1:before {
    content: "\ee77";
}

.icon-pinterest1:before {
    content: "\ee78";
}

.icon-pinterest2:before {
    content: "\ee79";
}

.icon-xing1:before {
    content: "\ee7a";
}

.icon-xing2:before {
    content: "\ee7b";
}

.icon-flattr1:before {
    content: "\ee7c";
}

.icon-foursquare1:before {
    content: "\ee7d";
}

.icon-yelp1:before {
    content: "\ee7e";
}

.icon-paypal1:before {
    content: "\ee7f";
}

.icon-chrome:before {
    content: "\ee80";
}

.icon-firefox:before {
    content: "\ee81";
}

.icon-IE:before {
    content: "\ee82";
}

.icon-edge:before {
    content: "\ee83";
}

.icon-safari1:before {
    content: "\ee84";
}

.icon-opera1:before {
    content: "\ee85";
}

.icon-file-pdf:before {
    content: "\ee86";
}

.icon-file-openoffice:before {
    content: "\ee87";
}

.icon-file-word:before {
    content: "\ee88";
}

.icon-file-excel:before {
    content: "\ee89";
}

.icon-libreoffice1:before {
    content: "\ee8a";
}

.icon-html-five:before {
    content: "\ee8b";
}

.icon-html-five2:before {
    content: "\ee8c";
}

.icon-css31:before {
    content: "\ee8d";
}

.icon-git1:before {
    content: "\ee8e";
}

.icon-codepen1:before {
    content: "\ee8f";
}

.icon-svg1:before {
    content: "\ee90";
}

.icon-IcoMoon:before {
    content: "\ee91";
}

.icon-airplay:before {
    content: "\ee92";
}

.icon-alert-circle:before {
    content: "\ee93";
}

.icon-alert-octagon:before {
    content: "\ee94";
}

.icon-alert-triangle:before {
    content: "\ee95";
}

.icon-align-center:before {
    content: "\ee96";
}

.icon-align-justify:before {
    content: "\ee97";
}

.icon-align-left:before {
    content: "\ee98";
}

.icon-align-right:before {
    content: "\ee99";
}

.icon-anchor1:before {
    content: "\ee9a";
}

.icon-aperture:before {
    content: "\ee9b";
}

.icon-archive:before {
    content: "\ee9c";
}

.icon-arrow-down1:before {
    content: "\ee9d";
}

.icon-arrow-down-circle:before {
    content: "\ee9e";
}

.icon-arrow-down-left1:before {
    content: "\ee9f";
}

.icon-arrow-down-right1:before {
    content: "\eea0";
}

.icon-arrow-left1:before {
    content: "\eea1";
}

.icon-arrow-left-circle:before {
    content: "\eea2";
}

.icon-arrow-right1:before {
    content: "\eea3";
}

.icon-arrow-right-circle:before {
    content: "\eea4";
}

.icon-arrow-up1:before {
    content: "\eea5";
}

.icon-arrow-up-circle:before {
    content: "\eea6";
}

.icon-arrow-up-left1:before {
    content: "\eea7";
}

.icon-arrow-up-right1:before {
    content: "\eea8";
}

.icon-at-sign:before {
    content: "\eea9";
}

.icon-award:before {
    content: "\eeaa";
}

.icon-bar-chart:before {
    content: "\eeab";
}

.icon-bar-chart-2:before {
    content: "\eeac";
}

.icon-battery:before {
    content: "\eead";
}

.icon-battery-charging:before {
    content: "\eeae";
}

.icon-bell1:before {
    content: "\eeaf";
}

.icon-bell-off:before {
    content: "\eeb0";
}

.icon-bluetooth:before {
    content: "\eeb1";
}

.icon-bold1:before {
    content: "\eeb2";
}

.icon-book1:before {
    content: "\eeb3";
}

.icon-book-open:before {
    content: "\eeb4";
}

.icon-bookmark1:before {
    content: "\eeb5";
}

.icon-box1:before {
    content: "\eeb6";
}

.icon-briefcase1:before {
    content: "\eeb7";
}

.icon-calendar1:before {
    content: "\eeb8";
}

.icon-camera1:before {
    content: "\eeb9";
}

.icon-camera-off:before {
    content: "\eeba";
}

.icon-cast:before {
    content: "\eebb";
}

.icon-check:before {
    content: "\eebc";
}

.icon-check-circle:before {
    content: "\eebd";
}

.icon-check-square:before {
    content: "\eebe";
}

.icon-chevron-down:before {
    content: "\eebf";
}

.icon-chevron-left:before {
    content: "\eec0";
}

.icon-chevron-right:before {
    content: "\eec1";
}

.icon-chevron-up:before {
    content: "\eec2";
}

.icon-chevrons-down:before {
    content: "\eec3";
}

.icon-chevrons-left:before {
    content: "\eec4";
}

.icon-chevrons-right:before {
    content: "\eec5";
}

.icon-chevrons-up:before {
    content: "\eec6";
}

.icon-chrome1:before {
    content: "\eec7";
}

.icon-circle1:before {
    content: "\eec8";
}

.icon-clipboard1:before {
    content: "\eec9";
}

.icon-clock1:before {
    content: "\eeca";
}

.icon-cloud1:before {
    content: "\eecb";
}

.icon-cloud-drizzle:before {
    content: "\eecc";
}

.icon-cloud-lightning:before {
    content: "\eecd";
}

.icon-cloud-off:before {
    content: "\eece";
}

.icon-cloud-rain:before {
    content: "\eecf";
}

.icon-cloud-snow:before {
    content: "\eed0";
}

.icon-code:before {
    content: "\eed1";
}

.icon-codepen11:before {
    content: "\eed2";
}

.icon-codesandbox1:before {
    content: "\eed3";
}

.icon-coffee:before {
    content: "\eed4";
}

.icon-columns:before {
    content: "\eed5";
}

.icon-command1:before {
    content: "\eed6";
}

.icon-compass1:before {
    content: "\eed7";
}

.icon-copy1:before {
    content: "\eed8";
}

.icon-corner-down-left:before {
    content: "\eed9";
}

.icon-corner-down-right:before {
    content: "\eeda";
}

.icon-corner-left-down:before {
    content: "\eedb";
}

.icon-corner-left-up:before {
    content: "\eedc";
}

.icon-corner-right-down:before {
    content: "\eedd";
}

.icon-corner-right-up:before {
    content: "\eede";
}

.icon-corner-up-left:before {
    content: "\eedf";
}

.icon-corner-up-right:before {
    content: "\eee0";
}

.icon-cpu:before {
    content: "\eee1";
}

.icon-credit-card1:before {
    content: "\eee2";
}

.icon-crop1:before {
    content: "\eee3";
}

.icon-crosshair:before {
    content: "\eee4";
}

.icon-database1:before {
    content: "\eee5";
}

.icon-delete:before {
    content: "\eee6";
}

.icon-disc:before {
    content: "\eee7";
}

.icon-dollar-sign:before {
    content: "\eee8";
}

.icon-download1:before {
    content: "\eee9";
}

.icon-download-cloud:before {
    content: "\eeea";
}

.icon-droplet1:before {
    content: "\eeeb";
}

.icon-edit:before {
    content: "\eeec";
}

.icon-edit-2:before {
    content: "\eeed";
}

.icon-edit-3:before {
    content: "\eeee";
}

.icon-external-link:before {
    content: "\eeef";
}

.icon-eye1:before {
    content: "\eef0";
}

.icon-eye-off:before {
    content: "\eef1";
}

.icon-facebook11:before {
    content: "\eef2";
}

.icon-fast-forward:before {
    content: "\eef3";
}

.icon-feather:before {
    content: "\eef4";
}

.icon-figma1:before {
    content: "\eef5";
}

.icon-file:before {
    content: "\eef6";
}

.icon-file-minus:before {
    content: "\eef7";
}

.icon-file-plus:before {
    content: "\eef8";
}

.icon-file-text1:before {
    content: "\eef9";
}

.icon-film1:before {
    content: "\eefa";
}

.icon-filter1:before {
    content: "\eefb";
}

.icon-flag1:before {
    content: "\eefc";
}

.icon-folder1:before {
    content: "\eefd";
}

.icon-folder-minus1:before {
    content: "\eefe";
}

.icon-folder-plus1:before {
    content: "\eeff";
}

.icon-framer1:before {
    content: "\ef00";
}

.icon-frown:before {
    content: "\ef01";
}

.icon-gift1:before {
    content: "\ef02";
}

.icon-git-branch:before {
    content: "\ef03";
}

.icon-git-commit:before {
    content: "\ef04";
}

.icon-git-merge:before {
    content: "\ef05";
}

.icon-git-pull-request:before {
    content: "\ef06";
}

.icon-github11:before {
    content: "\ef07";
}

.icon-gitlab1:before {
    content: "\ef08";
}

.icon-globe:before {
    content: "\ef09";
}

.icon-grid:before {
    content: "\ef0a";
}

.icon-hard-drive:before {
    content: "\ef0b";
}

.icon-hash:before {
    content: "\ef0c";
}

.icon-headphones1:before {
    content: "\ef0d";
}

.icon-heart1:before {
    content: "\ef0e";
}

.icon-help-circle:before {
    content: "\ef0f";
}

.icon-hexagon:before {
    content: "\ef10";
}

.icon-home1:before {
    content: "\ef11";
}

.icon-image1:before {
    content: "\ef12";
}

.icon-inbox:before {
    content: "\ef13";
}

.icon-info1:before {
    content: "\ef14";
}

.icon-instagram11:before {
    content: "\ef15";
}

.icon-italic1:before {
    content: "\ef16";
}

.icon-key1:before {
    content: "\ef17";
}

.icon-layers:before {
    content: "\ef18";
}

.icon-layout:before {
    content: "\ef19";
}

.icon-life-buoy:before {
    content: "\ef1a";
}

.icon-link1:before {
    content: "\ef1b";
}

.icon-link-2:before {
    content: "\ef1c";
}

.icon-linkedin11:before {
    content: "\ef1d";
}

.icon-list1:before {
    content: "\ef1e";
}

.icon-loader:before {
    content: "\ef1f";
}

.icon-lock1:before {
    content: "\ef20";
}

.icon-log-in:before {
    content: "\ef21";
}

.icon-log-out:before {
    content: "\ef22";
}

.icon-mail1:before {
    content: "\ef23";
}

.icon-map1:before {
    content: "\ef24";
}

.icon-map-pin:before {
    content: "\ef25";
}

.icon-maximize:before {
    content: "\ef26";
}

.icon-maximize-2:before {
    content: "\ef27";
}

.icon-meh:before {
    content: "\ef28";
}

.icon-menu1:before {
    content: "\ef29";
}

.icon-message-circle:before {
    content: "\ef2a";
}

.icon-message-square:before {
    content: "\ef2b";
}

.icon-mic1:before {
    content: "\ef2c";
}

.icon-mic-off:before {
    content: "\ef2d";
}

.icon-minimize:before {
    content: "\ef2e";
}

.icon-minimize-2:before {
    content: "\ef2f";
}

.icon-minus1:before {
    content: "\ef30";
}

.icon-minus-circle:before {
    content: "\ef31";
}

.icon-minus-square:before {
    content: "\ef32";
}

.icon-monitor:before {
    content: "\ef33";
}

.icon-moon:before {
    content: "\ef34";
}

.icon-more-horizontal:before {
    content: "\ef35";
}

.icon-more-vertical:before {
    content: "\ef36";
}

.icon-mouse-pointer:before {
    content: "\ef37";
}

.icon-move:before {
    content: "\ef38";
}

.icon-music1:before {
    content: "\ef39";
}

.icon-navigation:before {
    content: "\ef3a";
}

.icon-navigation-2:before {
    content: "\ef3b";
}

.icon-octagon:before {
    content: "\ef3c";
}

.icon-package:before {
    content: "\ef3d";
}

.icon-paperclip:before {
    content: "\ef3e";
}

.icon-pause1:before {
    content: "\ef3f";
}

.icon-pause-circle:before {
    content: "\ef40";
}

.icon-pen-tool:before {
    content: "\ef41";
}

.icon-percent:before {
    content: "\ef42";
}

.icon-phone1:before {
    content: "\ef43";
}

.icon-phone-call:before {
    content: "\ef44";
}

.icon-phone-forwarded:before {
    content: "\ef45";
}

.icon-phone-incoming:before {
    content: "\ef46";
}

.icon-phone-missed:before {
    content: "\ef47";
}

.icon-phone-off:before {
    content: "\ef48";
}

.icon-phone-outgoing:before {
    content: "\ef49";
}

.icon-pie-chart1:before {
    content: "\ef4a";
}

.icon-play1:before {
    content: "\ef4b";
}

.icon-play-circle:before {
    content: "\ef4c";
}

.icon-plus1:before {
    content: "\ef4d";
}

.icon-plus-circle:before {
    content: "\ef4e";
}

.icon-plus-square:before {
    content: "\ef4f";
}

.icon-pocket1:before {
    content: "\ef50";
}

.icon-power1:before {
    content: "\ef51";
}

.icon-printer1:before {
    content: "\ef52";
}

.icon-radio:before {
    content: "\ef53";
}

.icon-refresh-ccw:before {
    content: "\ef54";
}

.icon-refresh-cw:before {
    content: "\ef55";
}

.icon-repeat:before {
    content: "\ef56";
}

.icon-rewind:before {
    content: "\ef57";
}

.icon-rotate-ccw:before {
    content: "\ef58";
}

.icon-rotate-cw:before {
    content: "\ef59";
}

.icon-rss11:before {
    content: "\ef5a";
}

.icon-save:before {
    content: "\ef5b";
}

.icon-scissors1:before {
    content: "\ef5c";
}

.icon-search1:before {
    content: "\ef5d";
}

.icon-send:before {
    content: "\ef5e";
}

.icon-server:before {
    content: "\ef5f";
}

.icon-settings:before {
    content: "\ef60";
}

.icon-share1:before {
    content: "\ef61";
}

.icon-share-2:before {
    content: "\ef62";
}

.icon-shield1:before {
    content: "\ef63";
}

.icon-shield-off:before {
    content: "\ef64";
}

.icon-shopping-bag:before {
    content: "\ef65";
}

.icon-shopping-cart:before {
    content: "\ef66";
}

.icon-shuffle1:before {
    content: "\ef67";
}

.icon-sidebar:before {
    content: "\ef68";
}

.icon-skip-back:before {
    content: "\ef69";
}

.icon-skip-forward:before {
    content: "\ef6a";
}

.icon-slack1:before {
    content: "\ef6b";
}

.icon-slash:before {
    content: "\ef6c";
}

.icon-sliders:before {
    content: "\ef6d";
}

.icon-smartphone:before {
    content: "\ef6e";
}

.icon-smile1:before {
    content: "\ef6f";
}

.icon-speaker:before {
    content: "\ef70";
}

.icon-square1:before {
    content: "\ef71";
}

.icon-star:before {
    content: "\ef72";
}

.icon-stop-circle:before {
    content: "\ef73";
}

.icon-sun1:before {
    content: "\ef74";
}

.icon-sunrise:before {
    content: "\ef75";
}

.icon-sunset:before {
    content: "\ef76";
}

.icon-tablet1:before {
    content: "\ef77";
}

.icon-tag:before {
    content: "\ef78";
}

.icon-target1:before {
    content: "\ef79";
}

.icon-terminal1:before {
    content: "\ef7a";
}

.icon-thermometer:before {
    content: "\ef7b";
}

.icon-thumbs-down:before {
    content: "\ef7c";
}

.icon-thumbs-up:before {
    content: "\ef7d";
}

.icon-toggle-left:before {
    content: "\ef7e";
}

.icon-toggle-right:before {
    content: "\ef7f";
}

.icon-tool:before {
    content: "\ef80";
}

.icon-trash:before {
    content: "\ef81";
}

.icon-trash-2:before {
    content: "\ef82";
}

.icon-trello11:before {
    content: "\ef83";
}

.icon-trending-down:before {
    content: "\ef84";
}

.icon-trending-up:before {
    content: "\ef85";
}

.icon-triangle:before {
    content: "\ef86";
}

.icon-truck1:before {
    content: "\ef87";
}

.icon-tv1:before {
    content: "\ef88";
}

.icon-twitch11:before {
    content: "\ef89";
}

.icon-twitter11:before {
    content: "\ef8a";
}

.icon-type:before {
    content: "\ef8b";
}

.icon-umbrella:before {
    content: "\ef8c";
}

.icon-underline1:before {
    content: "\ef8d";
}

.icon-unlock:before {
    content: "\ef8e";
}

.icon-upload1:before {
    content: "\ef8f";
}

.icon-upload-cloud:before {
    content: "\ef90";
}

.icon-user1:before {
    content: "\ef91";
}

.icon-user-check1:before {
    content: "\ef92";
}

.icon-user-minus1:before {
    content: "\ef93";
}

.icon-user-plus1:before {
    content: "\ef94";
}

.icon-user-x:before {
    content: "\ef95";
}

.icon-users1:before {
    content: "\ef96";
}

.icon-video:before {
    content: "\ef97";
}

.icon-video-off:before {
    content: "\ef98";
}

.icon-voicemail:before {
    content: "\ef99";
}

.icon-volume:before {
    content: "\ef9a";
}

.icon-volume-1:before {
    content: "\ef9b";
}

.icon-volume-2:before {
    content: "\ef9c";
}

.icon-volume-x:before {
    content: "\ef9d";
}

.icon-watch:before {
    content: "\ef9e";
}

.icon-wifi:before {
    content: "\ef9f";
}

.icon-wifi-off:before {
    content: "\efa0";
}

.icon-wind:before {
    content: "\efa1";
}

.icon-x:before {
    content: "\efa2";
}

.icon-x-circle:before {
    content: "\efa3";
}

.icon-x-octagon:before {
    content: "\efa4";
}

.icon-x-square:before {
    content: "\efa5";
}

.icon-youtube11:before {
    content: "\efa6";
}

.icon-zap:before {
    content: "\efa7";
}

.icon-zap-off:before {
    content: "\efa8";
}

.icon-zoom-in1:before {
    content: "\efa9";
}

.icon-zoom-out1:before {
    content: "\efaa";
}
