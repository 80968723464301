.listStorePaginator {
    color: var(--color-primary) !important;
}

.advancedResearches {
    margin-bottom: 80px !important;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 40px 0 20px -20px;
    width: calc(100% + 40px);
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #c0c0c0;
}

.separator:not(:empty)::before {
    margin-right: 5%;
}

.separator:not(:empty)::after {
    margin-left: 5%;
}

@media (max-width: 600px) {
    .container {
        background-color: #ffffff;
    }
    .title {
        display: none;
    }
    .contentResult {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 1000px) {
    .advancedResearches {
        margin-bottom: 60px !important;
    }
}

@media screen and (max-width: 800px) {
    .advancedResearches {
        margin-bottom: 40px !important;
    }
}