.container {
    width: 100%;
}

.image {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    padding: 10px;
    border-radius: 5px;
}

@media (max-width: 680px) {
    .galleryImage {
        width: 100% !important;
    }
}
