.label {
    margin-right: 20px;
    color: #000000;
    font-weight: 600;
}

.input {
    width: 110px;
    height: 29px;
    text-align: center;
    box-shadow: inset 0 1.5px 3px rgba(17, 77, 130, 0.25);
    background-color: var(--color-grey-payment);
    color: var(--color-primary);
    font-weight: bold;
    font-size: 17px;
}

@media (max-width: 690px) {
    .input {
        width: 100% !important;
        font-size: 14px;
    }
}
