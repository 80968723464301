.results {
    background-color: white;
    overflow-y: auto;
}

.resultWithMap,
.map {
    display: flex;
    height: calc(100vh - var(--height-filtersSection) - var(--height-header));
}

.listOnly {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

@media (max-width: 1280px) {
    .map {
        > div {
            position: relative !important;
        }
    }

    .resultWithMap,
    .map {
        height: calc(100vh - var(--height-filtersSectionTablet) - var(--height-headerTablet));
    }
}

@media (max-width: 800px) {
    .map {
        display: flex;
        height: calc(100vh - var(--height-filtersSectionMobile) - var(--height-headerMobile));
    }

    .resultWithMap {
        width: 0;
        display: none;
    }

    .results {
        max-width: unset;
        height: unset;
        overflow-y: unset;
    }

    .container {
        width: 100%;
        height: 100%;
    }

    .contentResult {
        flex-direction: row-reverse;
    }
}
