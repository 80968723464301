.container {
    min-height: calc(470px + 200px);
}

.content {
    height: 470px;
    background-color: var(--color-primary);
}

.subContent {
    height: 145%;
    position: relative;
}

.image {
    position: absolute;
    right: 42%;
    top: 120px;
    display: flex;
    max-width: 775px;
}

.text {
    color: white;
}

.title {
    position: absolute;
    left: calc(12% + 45% + 70px);
    top: 130px;
    font-weight: 700;
    font-size: clamp(15px, 1.6vw, 24px);
    line-height: clamp(15px, 1.6vw, 18px);
    letter-spacing: 0.1em;
    color: #ffffff;
}

.li {
    font-weight: 500;
    font-size: clamp(12px, 2vw, 17px);
    line-height: clamp(18px, 2vw, 25px);
    margin-left: -15px;
    color: white;
}

.bottomText {
    position: absolute;
    bottom: 20px;
    left: calc(12% + 45% + 95px);
}
.bottomText {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
}

.bottomTitle {
    color: var(--color-font);
    font-weight: 500;
    font-size: clamp(12px, 2vw, 17px);
    line-height: clamp(18px, 2vw, 25px);
}

.contentLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list {
    position: absolute;
    top: 180px;
    left: calc(12% + 45% + 70px);
    list-style: none;
    margin-left: -1.5%;
}

.list li::before {
    content: "\2022";
    color: #ffc435;
    margin-right: 15px;
}

.logoacq {
    height: 140px;
}

.logoapcha {
    height: 140px;
}

@media (max-width: 1200px) {
    .logo {
        display: flex;
    }
    .logoacq {
        height: 126px;
    }

    .logoapcha {
        height: 156px;
    }
    .title {
        left: calc(15% + 45% + 30px);
    }

    .list {
        left: calc(15% + 45% + 30px);
    }
    .bottomText {
        bottom: -20px;
        left: calc(15% + 45% + 30px);
    }

    .content {
        height: 400px;
    }
}

@media (max-width: 980px) {
    .container {
        min-height: 700px;
    }
    .title {
        left: 50%;
        top: 20px;
        transform: translate(-50%, 0);
    }

    .list {
        left: 50%;
        top: 80px;
        transform: translate(-50%, 0);
    }

    .image {
        left: 50%;
        top: calc(300px);
        transform: translate(-50%, 0);
        width: 330px;
    }

    .bottomText {
        width: 330px;
        left: 50%;
        top: 450px;
        transform: translate(-50%, 0);
    }

    .li {
        width: 330px;
    }
}

@media (max-width: 680px) {
    .image {
        width: 90%;
    }

    .bottomText {
        width: 330px;
        left: 50%;
        top: calc(480px);
        transform: translate(-50%, 0);
    }
}
