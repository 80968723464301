.column {
    li {
        list-style: none;
        color: var(--color-text-black);
        font-size: 16px;
        margin-bottom: 7px;
    }
}

.column {
    columns: 2 auto;
}

.columnsMobile {
    display: none;
}

.title {
    font-weight: var(--font-weight-600);
}

@media (max-width: 800px) {
    .title {
        font-size: 22px;
    }

    .column {
        columns: 1 auto;
    }

    .columnsMobile {
        display: block;
    }

    .columns {
        display: none;
    }

    .column {
        & li {
            font-size: 14px;
        }
    }

    .collapse {
        transition: all 0.5s ease-in;
    }
}
