.isEditable {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.8;
    background-color: var(--color-primary);
    z-index: var(--zIndex-modal);
    border-radius: 3px;
}
