.container {
    margin-top: 110px;
    margin-bottom: 110px;
}

.summaryContainer {
    margin-top: 50px;
    margin-bottom: 50px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.button {
    font-family: "Poppins", Gadget, sans-serif;
    font-size: 16px;
    background-color: var(--color-primary-bg);
    padding: 10px 20px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--color-primary) !important;
}

.title {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .summaryContainer {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
    }
}
