.container {
    display: flex;
    align-items: center;
}

.budget-select {
    width: 200px;
    border: 1px solid var(--color-primary);
    margin: 0 8px;
}

.label {
    color: var(--color-primary);
}

.budget-divider {
    margin: 0 8px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
}
