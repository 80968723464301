.phoneContainer,
.container {
    position: relative;
    background-color: white;
    border: 1px solid var(--color-secondary);
    border-radius: 5px;
    padding: 18px 21px 28px 21px;
    width: 100%;
    max-width: 412px;
    box-shadow: 0 0 4px 0 #00000040;
}

.phoneContainer {
    border-top: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 28px 21px;
}

.phoneTextContainer {
    border-bottom: 1px solid var(--color-primary);
}

.container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.orContainer {
    position: absolute;
    top: -16px;
    font-weight: 600;
    color: var(--color-primary);
    font-size: 22px;
    line-height: 1.5em;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.or {
    background-color: white;
    padding: 0 10px;
}

.firstTabSelected {
    border-top-left-radius: 0 !important;
}

.tabSelected {
    background-color: var(--color-secondLvl-bg);
}

.text {
    font-size: 18px;
    padding-bottom: 3px;
    color: var(--color-primary) !important;
    text-decoration: none !important;
}

.icon {
    height: 12px;
    color: var(--color-primary) !important;
    text-decoration: none !important;
}

.nameContact {
    color: var(--color-text-black);
    font-size: 16px;
}

.textCheckbox {
    color: var(--color-text-black);
    font-weight: 400;
    font-size: 12px;
}

@media (max-width: 600px) {
    .container {
        width: 100%;
    }
}
.mentionContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.mentionText {
    text-align: center;
    font-size: 12px;
    width: 60%;
}
