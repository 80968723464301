.container {
    border-bottom: 1px solid var(--color-border);
}
.checkbox {
    user-select: none;
}

.check {
    color: var(--color-primary);
    padding: 5px !important;
    padding-left: 0 !important;
}

@media (max-width: 1100px) {
    .container {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid var(--color-primary);
    }
}
