.endAdornment {
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
}

.popup {
    height: 100%;
    border-radius: 0;
}
