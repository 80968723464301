.container {
    flex: 1;
    width: 100%;
}

/*@media (max-width: 1280px) {*/
/*    .container {*/
/*        height: 300px;*/
/*        width: 100%;*/
/*        > div {*/
/*            position: relative !important;*/
/*        }*/
/*    }*/
/*}*/
