.textarea::placeholder {
    color: #a7b3bd;
}

.textarea {
    height: 85%;
    overflow-y: scroll;
    width: 100%;
    box-shadow: inset 0 0.5px 3.328px rgba(17, 77, 130, 0.25);
    padding: 10px;
    font-family: Poppins, serif;
    letter-spacing: 0.1em;
    font-weight: bold;
    color: var(--color-primary);
}
