.breadCrumbContainer {
    margin: 35px auto 20px auto;
}
@media (max-width: 980px) {
    .container {
        margin-top: 10px;
    }
    .breadCrumbContainer {
        padding-right: 50px;
        padding-left: 50px;
    }
}
