.container {
    height: 70vh;
}

.image {
    position: absolute;
    top: -130px;
    width: 100%;
    height: calc(100% + 130px);
    z-index: 1;
}

.overlay {
    position: absolute;
    top: -130px;
    right: 0;
    left: 0;
    background: rgba(24, 40, 54, 0.5);
    height: calc(100% + 130px);
    width: 100%;
    z-index: 2;
}

.content {
    height: 40%;
    max-height: 250px;
    max-width: 800px;
    width: 80%;
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    z-index: 3;
    padding: 50px;
}

.titleContent {
    font-weight: 700;
    text-align: center;
    font-size: 28px;
    line-height: 1.3;
    letter-spacing: 0.1em;
    margin-bottom: 40px;
}

.contentBtn {
    cursor: pointer;
    width: 75%;
    max-width: 350px;
    background: var(--color-primary);
    color: white;
    padding: 6px 32px;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
}

@media (max-width: 1080px) {
    .container {
        height: 600px;
    }
    .content {
        padding: 20px;
    }

    .titleContent {
        margin-bottom: 11px;
    }

    .contentBtn {
        padding-right: 5px !important;
        padding-left: 5px !important;
        margin-bottom: 15px;
    }
}

@media (max-width: 680px) {
    .container {
        height: 400px;
    }
    .content {
        height: 225px;
    }

    .titleContent {
        font-size: 22px;
    }

    .contentBtn {
        width: 100%;
    }
}
