.container {
    height: 100%;
}

.content {
    width: 100%;
    height: calc(75vh - 123px);
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    margin-left: 1px;
}

.contentOverflowVisible {
    overflow-y: visible !important;
}

.contentOverflowScroll {
    overflow-y: scroll !important;
}

.form {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.firstColumn {
    width: 30%;
}

.secondColumn {
    width: 60%;
}

.open {
    display: block;
    height: calc(100% - 22px);
    transition: all 0.5s ease-in;
}

.labelNbInput {
    color: #000000;
    font-weight: 600;
}

.close {
    display: none;
    height: 0;
    transition: all 0.5s ease-in;
}

.title {
    font-weight: 700;
    font-size: clamp(16px, 1.5vw, 24px);
    line-height: 36px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--color-primary);
}

.number {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: right;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #a7b3bd;
}

.contentForm {
    height: 100%;
    max-height: 640px;
    width: 100%;
    padding-bottom: 4%;
}

.column {
    height: 98%;
    padding-bottom: 2%;
}

.level {
    height: 100%;
}

.extraLabel {
    margin-right: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.1em;
    color: #000000;
}

.label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    margin-top: 20px;
    color: black;
}

.twoInput {
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
}

.selectContent {
    align-self: flex-start;
}

.contentCheckbox {
    height: 65%;
}

.contentTextarea {
    height: 40%;
    margin-bottom: 1%;
}

.textDownloadFiles {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: 0.1em !important;
    margin-right: 25px;
}

.contentSelect {
    width: 100%;
    margin-top: 20px;
}

.item {
    display: flex;
    height: 60px;
    width: 100%;
    align-items: center;
}

.to {
    color: black;
    font-weight: bold;
    font-size: 17px;
}

@media (max-width: 690px) {
    .container {
        padding: 20px !important;
        height: 100%;
    }

    .form,
    .header {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .numbersInputs,
    .contentForm {
        flex-direction: column;
    }

    .content {
        height: initial;
    }

    .firstColumn,
    .secondColumn {
        width: 100%;
    }

    .numbersInputs > *,
    .firstColumn > *,
    .showPrice {
        margin-bottom: 10px;
    }

    .to {
        margin: 0 5px 0 5px;
        font-size: 12px;
    }

    .extraLabel {
        margin-right: 10px;
    }

    .labelNbInput,
    .extraLabel {
        font-size: 12px;
        min-width: 30%;
    }

    .rowInputNumber {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }
}
