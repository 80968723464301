.content {
    background-color: white;
    border-radius: 3px;
    -webkit-box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    -moz-box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    width: 575px;
    height: 31%;
}

.subContent {
    height: 100%;
    width: 100%;
}

.btnSeeMore {
    height: 100%;
    width: 160px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.column {
    display: flex;
    width: 393px;
}

.btn {
    width: 100%;
    background-color: var(--color-secondary);
    color: white;
    font-weight: 500;
    font-size: clamp(14px, 1.2vw, 18px);
    border-radius: var(--border-radius);
    border: 3px solid var(--color-secondary);
    white-space: nowrap;
    cursor: pointer;
    position: absolute;
    right: -7px;
    padding-right: 5%;
    padding-left: 5%;
    margin-bottom: 8%;
}

.textGradient {
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent, white);
}

.title {
    margin-bottom: 0;
    font-family: Poppins, serif;
    font-size: clamp(16px, 1.4vw, 24px);
    font-weight: 600;
    line-height: clamp(20px, 1.6vw, 36px);
    letter-spacing: 0;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 30%;
}

.text {
    position: relative;
    font-family: Poppins, serif;
    font-size: clamp(12px, 1.4vw, 16px);
    line-height: clamp(18px, 1.4vw, 24px);
    font-weight: 400;
    height: 50%;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-right: 2px;
    margin-top: 20px;
}

@media (max-width: 960px) {
    .content {
        height: 215px;
        width: 90vw;
    }
    .column {
        width: 90%;
        height: 190px;
    }
    .btnSeeMore {
        width: 30%;
        height: 20%;
        display: flex;
        align-self: flex-end;
        justify-self: flex-end;
    }
    .subContent {
        flex-direction: column;
        height: 100%;
    }
    .title {
        margin-bottom: 3px;
        text-overflow: clip;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 35%;
    }

    .btn {
        text-align: center;
    }

    .text {
        margin-top: 0;
    }
}
