.button {
    position: absolute !important;
    z-index: 2;
    font-size: 28px;
}

.isItemPage {
    right: 100px !important;
    bottom: 2px;
}

.iconCompare {
    position: absolute !important;
    color: white !important;
    z-index: 4;
    top: 5px;
    left: 5px;
    cursor: pointer;
}
