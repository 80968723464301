.container {
    position: fixed;
    left: 0;
    right: 0;
    background-color: transparent;
    min-height: 300px;
    line-height: 2;
}

.bgContainer {
    background-color: white;
    opacity: 0;
    margin-top: 0;
    transition: all 0.3s ease;
    padding: 25px 0 50px 0;
    border-bottom: 1px solid var(--color-border);
}

.bgContainerActive {
    opacity: 1;
    margin-top: 17px;
}

.columnContainer {
    /*margin-top: 15px;*/
}

.columnTitle {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-primary);
    padding: 0;
    margin-bottom: 10px;
}

.columnTitlePlaceholder {
    min-height: 40px;
    width: 100%;
}

.columnRow {
    border-bottom: 1px solid var(--color-border);
}

.columnRowLabel {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-placeholder) !important;
}

.columnRowLabel:hover {
    color: var(--color-primary) !important;
}
