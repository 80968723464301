.container {
    width: 100%;
    padding-top: 3%;
}

.title {
    color: var(--color-primary);
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-normal);
    font-size: clamp(20px, 1.6vw, 24px);
}

.lineYellow {
    width: 90%;
    border-bottom: var(--color-secondary) solid 3px;
    margin-bottom: 10px;
}

.priceSection {
    color: var(--color-primary);
    position: relative;
    font-weight: 700;
    align-items: baseline;
    font-size: clamp(30px, 1.6vw, 44px);
    margin-bottom: 20px;
}

.dollar {
    color: var(--color-primary);
    position: absolute;
    font-size: clamp(25px, 1.6vw, 26px);
    width: fit-content;
    top: 2px;
    left: -15px;
}

.price {
    color: var(--color-primary);
    font-size: clamp(30px, 2.4vw, 40px);
    margin-right: 10px;
    margin-left: 10px;
}

.smallPrice {
    font-size: 40px;
}

.smallDollar {
    font-size: 31px;
}

.month {
    color: var(--color-primary);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-huge);
    font-size: clamp(14px, 1.4vw, 20px);
}

.text {
    font-size: 13px;
    font-weight: normal;
}

@media (max-width: 980px) {
    .title {
        font-size: 22px;
    }

    .lineYellow {
        width: 130px;
    }

    .price {
        line-height: clamp(55px, 1.4vw, 80px);
        font-size: 45px;
    }
    .contentLinePrice {
        display: flex;
        flex-direction: column !important;
    }
}
