.container {
    margin-bottom: 80px;
}

.title {
    color: var(--color-primary);
    font-weight: 700;
    margin-bottom: 35px;
    text-align: left !important;
}

.propertySubTypeList {
    display: flex;
    flex-wrap: wrap;
}

.propertySubTypeItem {
    padding: 10px;
    margin: 0 1.25rem 1.25rem 0;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
}

@media screen and (max-width: 1000px) {
    .container {
        margin-bottom: 60px;
    }
    .propertySubTypeItem {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .container {
        margin-bottom: 40px;
    }
    .propertySubTypeItem {
        font-size: 12px;
    }
}
