.text {
    color: var(--color-text-black);
}

.link {
    display: inline-flex;
}

@media (max-width: 600px) {
    .text {
        font-size: 14px !important;
    }
}
