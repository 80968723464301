.dialog {
    margin: 5px;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.swiperContainer {
    position: relative;
    text-align: center;
    & img {
        border-radius: 5px;
        height: 85vh;
        object-fit: cover;
    }
}

.btn {
    position: absolute !important;
    top: 20px;
    left: 10px;
}

@media (max-width: 980px) {
    .btn {
        left: 10px;
        top: 25px;
    }
    .swiperContainer {
        & img {
            height: 100%;
            object-fit: cover;
        }
    }
    .contentSwiper {
        margin-top: calc(50% - 80px);
    }
}
