.button {
    font-size: 14px !important;
    font-weight: 400;
    padding: 12px;
    border-radius: 8px;
}

@media (max-width: 1100px) {
    .container {
        display: none;
        height: 0;
        padding: 0;
    }
}
