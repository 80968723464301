.container {
    height: 100%;
    width: 425px;
    max-width: calc(100vw - 32px);
}

.video,
.secondaryPhoto {
    height: calc(480px / 2 - 15px) !important;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius-gallery-propertyPage);
}

.video {
    padding-bottom: 8px;
}

.video:last-child {
    margin-top: 26px !important;
}

@media (max-width: 960px) {
    .container {
        display: none;
    }
}
