.content {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 12px;
}

.checkbox {
    height: 17px !important;
    width: 17px !important;
    margin-right: 20px;
    background: var(--color-secondLvl-bg);
    cursor: pointer;
}

.content input[type="checkbox"]:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background: var(--color-secondLvl-bg);
    border: 1px solid #004378;
    border-radius: 2px;
}
.content input[type="checkbox"]:checked:before {
    content: "\2714";
    color: var(--color-text-black) !important;
    width: 17px;
    height: 17px;
    background: var(--color-secondLvl-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}
