.justifyCenter {
    justify-content: center;
}

.container {
    width: 100%;
    & ul {
        list-style: none;
        flex-wrap: nowrap;
        display: flex;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }

    & li:after {
        font-family: "icomoon";
        content: " \eec1  ";
        padding: 0 5px;
        color: var(--color-secondary);
    }

    & li:last-child:after {
        content: unset;
    }

    li {
        white-space: nowrap;
    }

    .isBlog,
    li:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 600px) {
        li {
            font-size: 13px;
        }
    }
}

@media (max-width: 460px) {
    .container {
        max-height: 68px;
    }
}
