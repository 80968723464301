.container {
    width: 90%;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.resetPassword {
    font-weight: 700;
    font-size: clamp(8px, 1.2vw, 12px);
    line-height: clamp(12px, 1.2vw, 18px);
    letter-spacing: 0.2em;
    text-decoration-line: underline;
    color: var(--color-primary);
}

.button {
    padding-left: 80px !important;
    padding-right: 80px !important;
}

@media (max-width: 980px) {
    .container {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .button {
        height: 29px !important;
        font-size: 13px !important;
    }
}
