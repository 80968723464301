.titleDescription {
    margin: 0 !important;
    font-weight: var(--font-weight-600);
}

.line {
    border-bottom: solid 1px var(--color-primary);
    width: 100%;
    margin: 10px 0;
}

.arrow {
    width: 24px;
    height: 24px;
    /*transform: rotate(-90deg);*/
    transition: all 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}

@media (max-width: 960px) {
    .titleDescription {
        font-size: 22px;
    }
}
