.container {
    margin-bottom: 130px;
}

.content {
    height: 100%;
    width: 100%;
}

.img {
    object-fit: cover;
    width: 100%;
}

.contentText {
    border: 5px solid #ffffff;
    color: white;
    height: 90%;
    width: 90%;
}

.subContent {
    background: #ffffff;
    opacity: 0.95;
    padding: 4% 5%;
    margin: 4%;
}

.title {
    text-align: center;
    color: var(--color-primary);
    font-size: clamp(15px, 1.6vw, 26px);
    letter-spacing: 0.1em;
    margin: 2%;
}

.text {
    color: var(--color-primary);
    font-size: clamp(12px, 1.4vw, 17px);
    line-height: clamp(18px, 1.8vw, 36px);
    text-align: center;
}

@media (min-width: 900px) {
    .hiddenDesktop {
        display: none;
        height: 0;
    }
}

@media (max-width: 1500px) and (min-width: 900px) {
    .contentText {
        width: 90%;
    }

    .subContent {
        padding: 10px;
    }
}

@media (max-width: 900px) {
    .img,
    .content {
        min-height: 300px;
    }
    .unCollapseHeight {
        min-height: 600px;
    }

    .hiddenMobile {
        display: none;
        height: 0;
    }

    .contentText {
        margin-top: 0;
        align-items: initial;
        border: none;
        width: 100%;
        max-height: 100%;
    }

    .subContent {
        overflow: hidden;
    }
}
