.container {
    overflow: hidden;
}

.imgContainer {
    overflow: hidden;
    height: 120px;
    width: calc(100% - 10px);
    display: flex;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deleteIcon {
    font-size: 10px !important;
    position: absolute !important;
    top: 1px;
    right: 1px;
    background-color: var(--color-secondary) !important;
}

.deleteIcon:hover {
    transform: scale(1.1);
}

.cross {
    font-size: 15px !important;
}

@media (max-width: 500px) {
    .imgContainer {
        height: 90px;
    }
}
