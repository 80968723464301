.container {
    width: 100%;
    min-width: 350px;
}

.checkbox {
    width: 100% !important;
    user-select: none;
    border-bottom: 1px solid var(--color-border);
}

.checkbox:last-child {
    border-bottom: none;
}

.btn {
    color: var(--color-primary);
}

@media (max-width: 1100px) {
    .title {
        padding-left: 0;
    }
    .container {
        min-width: unset;
    }
}
