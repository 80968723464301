.container {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 580px;
    background-color: var(--color-primary);
    color: black;
    padding: 5px 11px 11px 11px;
    border-radius: 5px 5px 0 0;
    z-index: var(--zIndex-modal);
    transition: 0.5s ease all;
}

.collapsableSection {
    z-index: 20;
    height: 52px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 600;
    white-space: nowrap;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
}

.collapse {
    bottom: -241px;
    width: 370px;
}

.arrow {
    margin-bottom: 5px;
    width: 25px;
    height: 25px;
    background-image: url("../../assets/images/arrowPropertyGallery.svg");
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(-90deg);
}

.seeProject {
    font-weight: 700;
}

@media (max-width: 960px) {
    .container {
        width: 320px;
    }

    .collapsableSection {
        height: 40px;
    }

    .collapse {
        width: 200px;
    }

    .collapse {
        bottom: -211px;
    }

    .arrow {
        margin-bottom: 5px;
        background-size: 12px;
        width: 20px;
        height: 20px;
    }
    .collapsableSection {
        margin: 0 !important;
    }

    .seeProject {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .container {
        display: none;
    }
}
