@media (max-width: 960px) {
    .container::-webkit-scrollbar {
        display: none;
    }

    .container {
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
    }
}
