.container {
    background-color: var(--color-primary);
    height: 268px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    max-width: 660px;
    font-size: clamp(20px, 1.3vw, 24px);
    font-weight: 700;
    letter-spacing: var(--letter-spacing-normal);
    color: #ffffff;
    line-height: 1.3;
    text-align: center;
}

.btnContainer {
    gap: 70px;
}

.button {
    height: 35px;
    color: var(--color-primary);
    font-weight: 400;
    font-size: 18px;
    padding-inline: 24px;
    text-transform: none !important;
    width: 371px !important;
    border-radius: 2px !important;
    background-color: white !important;
    margin-top: 20px !important;
}

@media (max-width: 980px) {
    .btnContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }

    .container {
        width: 100vw;
        height: 380px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .text {
        width: 100%;
    }

    .button {
        width: 247px !important;
    }
}
