.text {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.095em;
    text-transform: uppercase;
    color: var(--color-primary);
    height: 25%;
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(17, 77, 130, 0.25);
    width: 234px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
}

.selected {
    background: #ffc435 !important;
}

.content {
    height: 100% !important;
}

@media (max-width: 690px) {
    .text {
        font-size: 16px;
        width: 25%;
    }

    .content {
        display: flex;
        flex-direction: row;
    }
}
