.title {
    font-weight: 600;
    color: var(--color-primary);
    font-size: 22px;
    margin-bottom: 24px;
    line-height: 1.5em;
}

@media (max-width: 600px) {
    .title {
        font-size: 20px;
    }
}
