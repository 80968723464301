.container {
    padding: 12px;
    height: 150px;
    margin-top: auto;
    > * {
        color: var(--color-text);
    }
}

.containerMap {
    height: auto;
    gap: 5px;
    & .title {
        font-size: 15px !important;
    }
    & .rooms,
    & .price,
    & .surface {
        font-size: 10px !important;
        min-height: unset !important;
        margin: 0 !important;
    }
}

.rooms,
.surface {
    color: var(--color-text-black) !important;
    font-weight: 400;
    min-height: 20px;
}

.leftCard {
    transform: translateX(-20px);
}

.user {
    color: #7a7e8d !important;
    max-width: 66%;
    font-weight: 400;
}

.title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5vh;
    letter-spacing: 1px;
}

.price {
    font-size: 16px;
    font-weight: 500;
}

.address {
    font-size: 11px;
    color: var(--color-text-black) !important;
    margin-bottom: 1vh;
    max-width: 66%;
}

.rooms > * {
    font-weight: 600;
}

.rooms,
.user,
.surface {
    font-size: 12px;
}

.isEditable {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.8;
    background-color: var(--color-primary);
    z-index: var(--zIndex-modal);
    border-radius: 3px;
}

.textCardMap {
    font-size: 12px;
}

.icon {
    color: var(--color-primary) !important;
}

@media (max-width: 1000px) {
    .cardLink {
        height: 100%;
        padding: 12px;
    }

    .title {
        font-size: 18px;
    }

    .price {
        font-size: 14px;
        font-weight: 500;
    }

    .surface {
        font-size: 11px;
    }

    .address {
        font-size: 12px;
    }

    .user,
    .rooms {
        font-size: 13px;
    }
}

@media (max-width: 600px) {
    .price {
        font-size: 13px;
    }

    .address,
    .rooms,
    .surface {
        font-size: 11px;
    }

    .user,
    .rooms {
        font-size: 12px;
    }
}
