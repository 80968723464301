.carteMenuContent {
    border: 8px solid var(--color-secondary);
    background: var(--color-primary-bg);
    padding: 24px 16px;
    font-weight: 600;
    line-height: 18px;
    font-size: 12px;
    letter-spacing: 0.2em;
}

.separator {
    height: 3px;
    width: 100%;
    background: var(--color-secondary);
}

.cartProductLine {
    display: flex;
    padding: 12px 0;
    align-items: center;
}

.cartProductLineText {
    flex: 1;
}

.cartProductLineNumber {
    margin-left: 40px;
    font-size: 16px;
}

.cartTotal {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}

.button {
    font-weight: 400;
    font-size: 12px;
    padding-inline: 24px;
}

.link {
    color: white !important;
    text-decoration: none !important;
}
