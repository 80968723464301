:root {
    --xs: 5px;
    --sm: 10px;
    --md: 20px;
    --lg: 50px;
    --xl: 100px;

    --bp-xl: 1280px;
    --bp-lg: 992px;
    --bp-md: 768px;
    --bp-sm: 576px;
    --bp-xs: 400px;

    --app-width: 1400px;

    --border-radius: 5px;
    --input-height: 36px;
    --letter-spacing-low: 3px;
    --letter-spacing-normal: 5px;
    --letter-spacing-huge: 8px;
    --border-radius-filters: 6px;
    --border-radius-see-more: 3px;

    --height-filters: 44px;
    --font-size-property-card: 10px;
    --border-radius-gallery-propertyPage: 2px;
    --font-weight-600: 600;
    --height-header: 54px;
    --height-headerTablet: 54px;
    --height-headerMobile: 54px;
    --height-map-header: 55px;
    --height-map-header-with-padding: 85px;
    --height-map-header-with-paddingTablet: 65px;
    --height-map-header-with-paddingMobile: 50px;
    --height-filtersSection: 88px;
    --height-filtersSectionTablet: 71px;
    --height-filtersSectionMobile: 69px;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.w-0 {
    width: 0;
}

.h-full {
    height: 100%;
}
