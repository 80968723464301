.container {
    margin-bottom: 120px;
    height: 100%;
}

.title {
    font-family: Poppins, serif;
    font-size: clamp(20px, 2vw, 32px);
    line-height: clamp(36px, 2.5vw, 48px);
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 80px;
    text-align: center;
}

.contentCard {
    padding: 0 !important;
    height: 100%;
}

@media (max-width: 980px) {
    .container {
        margin-bottom: 50px;
    }
    .title {
        margin-bottom: 20px;
    }
    .containerCard:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 15px;
    }
}
