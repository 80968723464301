.container {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    font-weight: 600;
    font-size: 12px;
    color: var(--color-primary);
}

.link {
    a {
        font-weight: 400 !important;
        font-size: 12px !important;
        color: var(--color-primary) !important;
    }

    border-bottom: 1px solid var(--color-border);
}

.link:last-child {
    border-bottom: 1px solid transparent;
}
