.container {
    margin: 0 0 80px 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.title {
    color: var(--color-primary);
    font-weight: 700;
    text-align: left !important;
    margin-bottom: 25px;
}

.answer {
    padding-left: 34px;
    & p {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 1000px) {
    .answer {
        padding-left: 24px;
        font-size: 15px;
        & p {
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 800px) {
    .answer {
        padding-left: 14px;
        font-size: 13px;
        & p {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .container {
        margin-bottom: 60px !important;
    }
}

@media screen and (max-width: 800px) {
    .container {
        margin-bottom: 40px !important;
    }
}