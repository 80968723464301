.formContainer {
    margin-top: 80px;
}

.initialStepContainer {
    margin: 60px 0;
    gap: 60px;
}

.stepContainer {
    margin: 60px;
    padding: 100px;
    position: relative;
    border: 4px solid var(--color-primary);
}

.homeImg {
    object-fit: cover;
}

.title {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 17px;
    font-weight: 400;
    color: var(--color-font);
    margin-bottom: 30px;
}

.btn {
    background-color: var(--color-primary);
    padding: 12px 40px;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--color-text-on-primary) !important;

    display: flex;
    align-items: center;
    gap: 10px;

    & a {
        color: var(--color-text-on-primary) !important;
    }
}

.step1Form,
.step3Form {
    margin: 0 240px;
}

.step4Form,
.step2Form {
    margin: 0 120px;
}

@media (max-width: 1600px) {
    .step1Form,
    .step3Form {
        margin: 0 120px;
    }

    .step4Form,
    .step2Form {
        margin: 0 60px;
    }

    .stepContainer {
        padding: 80px;
    }
}

@media (max-width: 1180px) {
    .step1Form,
    .step3Form,
    .step4Form,
    .step2Form {
        margin: 0;
    }

    .stepContainer {
        padding: 50px;
    }
}

@media (max-width: 768px) {
    .formContainer {
        margin-top: 20px;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
    }

    .stepContainer {
        margin: 20px 5px 5px 5px;
        padding: 20px;
        position: relative;
    }

    .homeImg {
        display: none;
    }
}
