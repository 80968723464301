.title {
    padding: 5% !important;
}

.actions {
    padding: 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.text {
    padding: 2% !important;
}
