.container {
    margin-bottom: 120px;
}

.placeholderContainer {
    overflow: hidden;
    padding: 40px 20px;
}

.title {
    font-family: Poppins, serif;
    font-size: clamp(20px, 2vw, 32px);
    line-height: clamp(36px, 2.5vw, 48px);
    font-weight: 700;
    letter-spacing: 0.1em;
    font-style: normal;
    margin-bottom: 80px;
    text-align: center;
}

@media (max-width: 980px) {
    .title {
        margin-bottom: 20px !important;
    }
    .container {
        margin-bottom: 50px !important;
    }
}
