.container {
    position: absolute;
    z-index: var(--zIndex-cookieBanner);
}

.hide {
    inset: unset;
}

.display {
    inset: 0;
}
