.container {
    width: 100%;
    margin-top: 50px !important;
}

.title {
    font-size: 32px !important;
    font-weight: var(--font-weight-600);
}

.details {
    flex: 1;
    padding-right: 25px;
}

.CTA {
    max-width: 425px;
}

.price {
    font-size: 28px;
    line-height: 25px;
    color: var(--color-text-black);
}

.from {
    color: var(--color-text-fade);
    font-size: 18px;
}

.priceTopBar {
    font-size: 22px;
    margin: 0 !important;
    color: var(--color-secondary);
}

.fromTopBar {
    font-size: 16px;
    color: #fff;
}

.text {
    color: var(--color-text-black);
}

.containerDescription {
    border-radius: 5px;
    background-color: #f5f6fa;
    padding: 30px 40px;
    color: var(--color-text-black);
    margin-right: 25px;
    width: 100%;
}

.titleDescription {
    margin: 0 !important;
    font-weight: var(--font-weight-600);
}

.btnMore {
    border: solid 2px var(--color-primary);
    color: var(--color-primary) !important;
    min-width: 135px;
    border-radius: 5px;
    padding: 10px 20px;
    margin: auto;
}

.collapse {
    transition: all 0.5s ease-in;
}

@media (max-width: 1280px) {
    .details {
        flex: 1;
    }
}

@media (max-width: 960px) {
    .container {
        flex-direction: column;
        margin-top: 20px !important;
    }

    .titleDescription {
        font-size: 22px;
    }

    .CTA {
        max-width: unset;
        margin-left: 0 !important;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .details {
        flex: 1;
        padding-right: 0;
    }

    .title {
        font-size: 22px !important;
        line-height: 27px !important;
    }

    .containerDescription {
        width: 100%;
    }
    .text {
        font-size: 12px !important;
    }
    .price {
        font-size: 22px !important;
    }
}
