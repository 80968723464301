.btnSort {
    color: var(--color-primary) !important;
    height: 36px;
    border: 0;
    margin-left: -10px;
}

.sortBy {
    font-weight: 400;
    padding-left: 10px;
    padding-right: 5px;
}

.iconSelect {
    margin-left: -20px;
}

.arrowBtn {
    cursor: pointer;
    top: 1px;
}

.iconDown {
    transform: rotate(180deg);
    cursor: pointer;
    bottom: 1px;
}

.contentArrow {
    height: 36px;
    width: 30px;
}

@media (max-width: 600px) {
    .iconSelect {
        width: 90px;
        height: var(--height-filters);
    }
    .sortBy,
    .btnSort {
        white-space: nowrap;
        font-size: 12px !important;
    }

    .btnSort {
        & div {
            padding-right: 20px !important;
        }
    }
}

@media (max-width: 400px) {
    .img {
        display: none;
    }
}
